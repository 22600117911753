import { useEffect } from "react";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import img from "../../../assets/imgs/royal-enfield-classic-350.jpg";

function HomeSlider(props) {
  const [index, setIndex] = useState(0);
  const [slider, setSlider] = useState(props.slider);

  useEffect(() => {
    setSlider(props.slider);
  }, [props.slider]);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      slide={true}
      infinite="true"
    >
      {slider.length > 0
        ? slider.map((img, index) => {
            return (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={img.photo}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>{img.title}</h3>
                  <p>{img.detail}</p>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })
        : ""}
    </Carousel>
  );
}

export default HomeSlider;
