import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { useState } from "react";
import { useEffect } from "react";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const HomeCustomer = (props) => {
  const [customers, setCustomers] = useState(props.customers);

  useEffect(() => {
    setCustomers(props.customers);
  }, [props.customers]);

  console.log("Customers", customers);

  return (
    <div className="bike-customer">
      <div className="section-title customer-title">
        <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon>
        <span>Happiest Customers</span>
      </div>
      <Carousel responsive={responsive} autoPlaySpeed={1000} autoPlay={true}>
        {customers.length > 0
          ? customers.map((customer, key) => {
              return (
                <div key={key}>
                  <div className="ride-wrapper">
                    <div className="ride">
                      <a href="#">
                        {" "}
                        <img src={customer.thumbnail} />
                        <div className="content">
                          <h4>{customer.title}</h4>
                          <p>
                            {customer.bike} - {customer.color}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </Carousel>
      <div className="view-more">
        <Link to="/customers" className="btn btn-outline-dark">
          View More >>
        </Link>
      </div>
    </div>
  );
};
export default HomeCustomer;
