import countReducer from "./counter";
import loggedReducer from "./isLogged";
import profileReducer from "./profileReducer";
import modelReducer from "./modelReducer";
import bikeReducer from "./bikeReducer";
import { combineReducers } from "redux";
import pageReducer from "./pageReducer";
import blogReducer from "./blogReducer";
import customerReducer from "./customerReducer";
import frontReducer from "./frontReducer";
import contactReducer from "./contactReducer";

const allReducers = combineReducers({
  count: countReducer,
  logged: loggedReducer,
  profile: profileReducer,
  model: modelReducer,
  bike: bikeReducer,
  page: pageReducer,
  blog: blogReducer,
  customer: customerReducer,
  front: frontReducer,
  contact: contactReducer,
});

export default allReducers;
