import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { base_url } from "../../constant";
import Footer from "./common/footer";
import Header from "./common/header";
import Leftbar from "./common/leftbar";
import Userprofile from "./dashboard/userprofile";
import Layout from "../../layout/layout";

const loadProfile = () => {
  return { type: "PROFILE_FETCHING_DATA_ATTEMPT" };
};
function Myprofile() {
  //   const store = useSelector((state) => state.profile);
  const actions = useDispatch();

  useEffect(() => {
    actions(loadProfile());
  }, []);

  const profile = useSelector((state) => state.profile.response);

  return (
    <Layout>
      <Userprofile />
    </Layout>
  );
}

export default Myprofile;
