import Layout from "../../../layout/layout";
import List from "./list";

const Model = () => {
  return (
    <Layout>
      <List />
    </Layout>
  );
};

export default Model;
