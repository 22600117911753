let initialState = {
  response: {
    customers: [],
    has_next_page: true,
  },
  isFetching: false,
  isError: false,
  isSuccess: false,
  detail: {},
};

export default function customerReducer(state = initialState, action) {
  switch (action.type) {
    case "FRONT_CUSTOMER_FETCHING_DATA_ATTEMPT":
      return {
        ...state,
        isError: false,
        isFetching: true,
        isSuccess: false,
      };
    case "CUSTOMER_FETCHING_DATA_SUCCESS":
      // console.log("customers", action.payload);
      return {
        ...state,
        response: {
          customers:
            action.payload.current_page > 1
              ? [...state.response.customers, ...action.payload.customers]
              : [...action.payload.customers],
          has_next_page: action.payload.has_next_page,
        },
        isFetching: false,
        isError: false,
        isSuccess: false,
      };
    case "CUSTOMER_FETCHING_DATA_FAILURE":
      return {
        ...state,
        isError: action.payload,
        isFetching: false,
        isSuccess: false,
      };

    case "CUSTOMER_ADD_DATA_SUCCESS":
      return {
        ...state,
        isError: action.payload,
        isFetching: false,
        isSuccess: true,
      };

    case "CUSTOMER_DETAIL_DATA_SUCCESS":
      return {
        ...state,
        isError: false,
        isFetching: false,
        isSuccess: false,
        detail: action.payload,
      };

    case "CUSTOMER_RESET_ALL_LOADING":
      return {
        ...state,
        isFetching: false,
        isError: false,
        isSuccess: false,
      };
    default:
      return state;
  }
}
