import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";

const submitForm = (formData) => {
  return { type: "BIKE_FRONT_BOOK_DATA_ATTEMPT", payload: { formData } };
};

const Description = (props) => {
  const store = useSelector((state) => state);
  const action = useDispatch();
  const bike = props.bike;
  const [bikeid, setBikeid] = useState(bike.id);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [addressError, setAddressError] = useState("");

  if (store.bike.isSuccess) {
    toast(
      "Success! Your booking request has been sent. We will contact you within 24 Hours."
    );
  }

  const handleForm = (event) => {
    event.preventDefault();
    let error = false;

    if (name == "") {
      setNameError("required");
      error = true;
    }

    if (email == "") {
      setEmailError("required");
      error = true;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailError("required");
      error = true;
    }

    if (mobile == "") {
      setMobileError("required");
      error = true;
    }

    if (address == "") {
      setAddressError("required");
      error = true;
    }

    if (error == true) return false;

    const formData = new FormData();
    // formData.append("files", imageFile);
    formData.append("bikeid", bikeid);
    formData.append("name", name);
    formData.append("mobile", mobile);
    formData.append("email", email);
    formData.append("address", address);

    action(submitForm(formData));
  };

  return (
    <>
      <div className="bike_detail">
        <h4>{bike.name}</h4>
        <div dangerouslySetInnerHTML={{ __html: bike.detail }}></div>
        {/* {bike.detail} */}
        <nav class="menu-bar menu-bar-bike">
          <span class="stick right share-icon">
            {" "}
            <FacebookShareButton url={window.location.href} quote={bike.name}>
              <FacebookIcon size={40} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href} title={bike.name}>
              <TwitterIcon size={40} round={true} />
            </TwitterShareButton>
            <WhatsappShareButton url={window.location.href} title={bike.name}>
              <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>
            <LinkedinShareButton url={window.location.href} title={bike.name}>
              <LinkedinIcon size={40} round={true} />
            </LinkedinShareButton>
            {bike.manual ? (
              <button className="btn-download">
                <a
                  href={bike.manual}
                  title="Download Bike Specification"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                </a>
              </button>
            ) : (
              ""
            )}
          </span>
        </nav>
      </div>
      <div className="test_ride_form">
        <div
          className="bg-img"
          style={{ backgroundImage: `URL(${bike.photo})` }}
        >
          <form
            action="/action_page.php"
            className="ride-container"
            onSubmit={(e) => {
              handleForm(e);
            }}
          >
            <h5>Book Bike</h5>
            <div className="ride-form-wrapper">
              <div className="ride-form-left">
                <label htmlFor="name">
                  <b>Name</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError("");
                  }}
                  className={nameError}
                />

                <label htmlFor="email">
                  <b>Email</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Email"
                  name="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                  className={emailError}
                />
              </div>
              <div className="ride-form-right">
                <label htmlFor="mobile">
                  <b>Mobile Number</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Mobile Number"
                  name="mobile"
                  onChange={(e) => {
                    setMobile(e.target.value);
                    setMobileError("");
                  }}
                  className={mobileError}
                />

                <label htmlFor="address">
                  <b>Address</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Address"
                  name="address"
                  onChange={(e) => {
                    setAddress(e.target.value);
                    setAddressError("");
                  }}
                  className={addressError}
                />
                <button type="submit" className="btn-ride">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Description;
