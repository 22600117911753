import { ofType } from "redux-observable";
import { catchError, switchMap, mergeMap, delay } from "rxjs/operators";
import { from, of } from "rxjs";
import { base_url } from "../constant";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export const fetchPagesApi = async (action) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "pages", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      page: action.payload.page,
    },
  });
  return res;
};

export const fetchPageEpic = (action$) =>
  action$.pipe(
    ofType("PAGE_FETCHING_DATA_ATTEMPT", "PAGE_DELETE_DATA_SUCCESS"),
    mergeMap((action) =>
      from(fetchPagesApi(action)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "PAGE_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "PAGE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const addpagesApi = async (formData) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "page/add", formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const addPageEpic = (action$) =>
  action$.pipe(
    ofType("PAGE_ADD_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(addpagesApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "PAGE_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "PAGE_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchDetailPageApi = async (payload) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "page/" + payload.pageid, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      // modelid: payload.modelid,
    },
  });
  return res;
};

export const fetchDetailPageEpic = (action$) =>
  action$.pipe(
    ofType("PAGE_DETAIL_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchDetailPageApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          if (action.cb) {
            action.cb(response?.data);
          }
          return of({
            type: "PAGE_DETAIL_DATA_SUCCESS",
            payload: response?.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "PAGE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const editPageApi = async (formData, pageid) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "page/edit/" + pageid, formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const editPageEpic = (action$) =>
  action$.pipe(
    ofType("PAGE_EDIT_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(editPageApi(action.payload, action.pageid)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "PAGE_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "PAGE_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const deletePageApi = async (pageid) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(
    base_url + "page/delete/" + pageid,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res;
};

export const deletePageEpic = (action$) =>
  action$.pipe(
    ofType("PAGE_DELETE_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(deletePageApi(action.payload.pageid)).pipe(
        mergeMap((response) => {
          toast("Success!");
          console.log("response", response);
          return of({
            type: "PAGE_DELETE_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "PAGE_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );
