import Layout from "../../../layout/layout";
import EditForm from "./editForm";

const EditBlog = () => {
  return (
    <Layout>
      <EditForm />
    </Layout>
  );
};

export default EditBlog;
