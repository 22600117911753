import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import loadingImage from "../../../assets/imgs/loading.gif";
import TrPage from "./trPage";

const loadPages = (page) => {
  return { type: "PAGE_FETCHING_DATA_ATTEMPT", payload: { page } };
};

const List = () => {
  const action = useDispatch();
  const store = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    action(loadPages(page));
    setLoading(true);
  }, [page]);

  const loadMorePages = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && store.page.response.has_next_page) {
      setPage(page + 1);
    }
  };
  return (
    <div className="page_wrapper">
      <div className="page-header">
        <h1>
          Pages{" "}
          {/* <Link to="/admin/page/add" className="btn btn-primary float-right">
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add
          </Link> */}
        </h1>
      </div>

      <div className="row">
        <div
          className="col-sd-12 col-md-12  body-list"
          style={{ overflowX: "auto" }}
          onScroll={(event) => {
            loadMorePages(event);
          }}
        >
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Page Name</th>
                <th scope="col">Image</th>
                <th scope="col">Status</th>
                <th scope="col"> Action </th>
              </tr>
            </thead>
            <tbody>
              {store.page.response.pages.length > 0 ? (
                store.page.response.pages.map((page) => (
                  <TrPage key={page.id} page={page} />
                ))
              ) : (
                <tr>
                  <td scope="row" colSpan={5} style={{ textAlign: "center" }}>
                    {loading ? <img src={loadingImage} /> : "No page found"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default List;
