import { ofType } from "redux-observable";
import { catchError, switchMap, mergeMap, delay } from "rxjs/operators";
import { from, of } from "rxjs";
import { base_url } from "../constant";
import axios from "axios";
import { toast } from "react-toastify";

export const fetchBikeModelsApi = async (action) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "preset/models", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      page: 1,
    },
  });
  return res;
};

export const fetchBikeModelEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_MODEL_FETCH_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchBikeModelsApi(action)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "BIKE_MODEL_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "BIKE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchBikesApi = async (action) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "bikes", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      page: action.payload.page,
    },
  });
  return res;
};

export const fetchBikeEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_FETCHING_DATA_ATTEMPT", "BIKE_DELETE_DATA_SUCCESS"),
    mergeMap((action) =>
      from(fetchBikesApi(action)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "BIKE_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "BIKE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const addBikeApi = async (formData) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "bike/add", formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const addBikeEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_ADD_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(addBikeApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log("Bikeresponse", response);
          return of({
            type: "BIKE_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchBikeDetailApi = async (payload) => {
  console.log("Bike id:", payload.bikeid);
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "bike/" + payload.bikeid, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      // modelid: payload.modelid,
    },
  });
  return res;
};

export const fetchBikeDetailEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_DETAIL_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchBikeDetailApi(action.payload)).pipe(
        mergeMap((response) => {
          if (action.cb) {
            action.cb(response?.data);
          }
          return of({
            type: "BIKE_DETAIL_DATA_SUCCESS",
            payload: response?.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const editBikeApi = async (formData, bikeid) => {
  // console.log("Features:", formData);
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "bike/edit/" + bikeid, formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const editBikeEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_EDIT_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(editBikeApi(action.payload, action.bikeid)).pipe(
        mergeMap((response) => {
          console.log("responsebike", response);
          return of({
            type: "BIKE_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const deleteBikeApi = async (bikeid) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(
    base_url + "bike/delete/" + bikeid,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res;
};

export const deleteBikeEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_DELETE_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(deleteBikeApi(action.payload.bikeid)).pipe(
        mergeMap((response) => {
          toast("Success!");
          console.log("response", response);
          return of({
            type: "BIKE_DELETE_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchColorBikesApi = async (action) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "preset/bikes", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      page: 1,
    },
  });
  return res;
};

export const fetchColorBikesEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_PRESET_FETCH_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchColorBikesApi(action)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "BIKE_PRESET_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "BIKE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const addBikeColorApi = async (formData) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "bike/add_colors", formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const addBikeColorEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_COLOR_ADD_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(addBikeColorApi(action.payload)).pipe(
        mergeMap((response) => {
          // console.log("Bikeresponse", response);
          return of({
            type: "BIKE_COLOR_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchBikeColorsApi = async (action) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "bike/bike_colors", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      page: action.payload.page,
      bikeid: action.payload.bikeid,
    },
  });
  return res;
};

export const fetchBikeColorEpic = (action$) =>
  action$.pipe(
    ofType(
      "BIKE_COLOR_FETCHING_DATA_ATTEMPT",
      "BIKE_COLOR_DELETE_DATA_SUCCESS"
    ),
    mergeMap((action) =>
      from(fetchBikeColorsApi(action)).pipe(
        mergeMap((response) => {
          // console.log("Bike photos:", response);
          return of({
            type: "BIKE_COLOR_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "BIKE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchBikeColorDetailApi = async (payload) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(
    base_url + "bike/bike_color/" + payload.bike_colorid,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {
        // modelid: payload.modelid,
      },
    }
  );
  return res;
};

export const fetchBikeColorDetailEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_COLOR_DETAIL_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchBikeColorDetailApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          if (action.cb) {
            action.cb(response?.data);
          }
          return of({
            type: "BIKE_COLOR_DETAIL_DATA_SUCCESS",
            payload: response?.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const editBikeColorApi = async (formData, bike_colorid) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(
    base_url + "bike/color/edit/" + bike_colorid,
    formData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res;
};

export const editBikeColorEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_COLOR_EDIT_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(editBikeColorApi(action.payload, action.bike_colorid)).pipe(
        mergeMap((response) => {
          console.log("responsebike", response);
          return of({
            type: "BIKE_COLOR_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const deleteBikeColorApi = async (bikeid) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(
    base_url + "bike/color/delete/" + bikeid,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res;
};

export const deleteBikeColorEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_COLOR_DELETE_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(deleteBikeColorApi(action.payload.bikeColorId)).pipe(
        mergeMap((response) => {
          toast("Success!");
          console.log("response", response);
          return of({
            type: "BIKE_COLOR_DELETE_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const addBikePhotoApi = async (formData) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "bike/add_photo", formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const addBikePhotoEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_COLOR_ADD_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(addBikePhotoApi(action.payload)).pipe(
        mergeMap((response) => {
          // console.log("Bikeresponse", response);
          return of({
            type: "BIKE_PHOTO_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchBikePhotsApi = async (action) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "bike/bike_photos", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      page: action.payload.page,
      bikeid: action.payload.bikeid,
    },
  });
  return res;
};

export const fetchBikePhotoEpic = (action$) =>
  action$.pipe(
    ofType(
      "BIKE_PHOTO_FETCHING_DATA_ATTEMPT",
      "BIKE_PHOTO_DELETE_DATA_SUCCESS"
    ),
    mergeMap((action) =>
      from(fetchBikePhotsApi(action)).pipe(
        mergeMap((response) => {
          // console.log("Bike photos:", response);
          return of({
            type: "BIKE_PHOTO_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "BIKE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchBikePhotoDetailApi = async (payload) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(
    base_url + "bike/bike_photo/" + payload.bike_photoid,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {
        // modelid: payload.modelid,
      },
    }
  );
  return res;
};

export const fetchBikePhotoDetailEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_PHOTO_DETAIL_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchBikePhotoDetailApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          if (action.cb) {
            action.cb(response?.data);
          }
          return of({
            type: "BIKE_PHOTO_DETAIL_DATA_SUCCESS",
            payload: response?.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const editBikePhotoApi = async (formData, bike_colorid) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(
    base_url + "bike/photo/edit/" + bike_colorid,
    formData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res;
};

export const editBikePhotoEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_PHOTO_EDIT_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(editBikePhotoApi(action.payload, action.bike_photoid)).pipe(
        mergeMap((response) => {
          // console.log("responsebike", response);
          return of({
            type: "BIKE_PHOTO_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const deleteBikePhotoApi = async (bikeid) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(
    base_url + "bike/photo/delete/" + bikeid,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res;
};

export const deleteBikePhotoEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_PHOTO_DELETE_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(deleteBikePhotoApi(action.payload.bikePhotoId)).pipe(
        mergeMap((response) => {
          toast("Success!");
          console.log("response", response);
          return of({
            type: "BIKE_PHOTO_DELETE_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchFrontBikeDetailApi = async (payload) => {
  console.log("Bike slug:", payload.slug);
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "bike/detail/" + payload.slug, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      // modelid: payload.modelid,
    },
  });
  return res;
};

export const fetchFrontBikeDetailEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_FRONT_DETAIL_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchFrontBikeDetailApi(action.payload)).pipe(
        mergeMap((response) => {
          if (action.cb) {
            action.cb(response?.data);
          }
          return of({
            type: "BIKE_DETAIL_DATA_SUCCESS",
            payload: response?.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchFrontBikeBookApi = async (formData) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "bike/book", formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const fetchFrontBikeBookEpic = (action$) =>
  action$.pipe(
    ofType("BIKE_FRONT_BOOK_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchFrontBikeBookApi(action.payload.formData)).pipe(
        mergeMap((response) => {
          return of({
            type: "BIKE_ADD_DATA_SUCCESS",
            // payload: response?.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchFrontBikeListApi = async () => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "front/bikelist", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      // modelid: payload.modelid,
    },
  });
  return res;
};

export const fetchFrontBikeListEpic = (action$) =>
  action$.pipe(
    ofType("FRONT_BIKE_FETCH_ATTEMPT"),
    mergeMap((action) =>
      from(fetchFrontBikeListApi()).pipe(
        mergeMap((response) => {
          return of({
            type: "BIKE_FETCHING_DATA_SUCCESS",
            payload: response?.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BIKE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchOrdersApi = async (action) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "bike/orders", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      page: action.payload.page,
      keyword: action.payload.keyword,
      date: action.payload.date,
    },
  });
  return res;
};

export const fetchOrderEpic = (action$) =>
  action$.pipe(
    ofType("ORDER_FETCHING_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchOrdersApi(action)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "ORDER_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "CUSTOMER_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchContactsApi = async (action) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "bike/contacts", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      page: action.payload.page,
      keyword: action.payload.keyword,
      date: action.payload.date,
    },
  });
  return res;
};

export const fetchContactEpic = (action$) =>
  action$.pipe(
    ofType("CONTACT_FETCHING_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchContactsApi(action)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "CONTACT_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "CUSTOMER_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );
