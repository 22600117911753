import { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { base_url } from "../../constant";
import { useNavigate } from "react-router-dom";
import loading_img from "../../assets/imgs/loading.gif";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [email_error, setEmail_error] = useState("");
  const [password_error, setPassword_error] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleForm = (e) => {
    e.preventDefault();
    let error = 0;
    if (email == "") {
      setEmail_error("Email is required");
      error = 1;
    }

    if (password == "") {
      setPassword_error("Password is required");
      error = 1;
    }

    if (error == 1) return false;

    setLoading(true);

    axios
      .post(base_url + "authenticate", {
        email,
        password,
      })
      .then(function (response) {
        localStorage.setItem("ramro_token", response.data.access_token);
        navigate("/admin");
      })
      .catch(function (error) {
        setLoading(false);
        setError(error.response.data.message);
      });
  };

  return (
    <div className="loginWrapper">
      <div className="login-form-wrapper ">
        {error ? (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        ) : (
          ""
        )}
        <div className="loading">
          {loading ? <img src={loading_img} /> : ""}
        </div>
        <h3>Vintage Moto Cafe</h3>
        <div className="page-links">
          <a href="#" className="active">
            Login
          </a>
        </div>
        <form onSubmit={handleForm}>
          <div className="login-field-wrapper ">
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                aria-describedby="emailHelp"
                placeholder="Enter email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError(null);
                }}
              />
              <div>{email_error}</div>
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                value={password}
                id="password"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError(null);
                }}
              />
            </div>
            <div>{password_error}</div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
