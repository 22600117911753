import HomeBikes from "./home/homeBikes";
import HomeSlider from "./home/homeslider";
import Rides from "./home/rides";
import FrontLayout from "./layout/layout";
import HomeCustomer from "./home/homeCustomer";
import PageVideo from "./video";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import loading from "../../assets/imgs/loading.gif";

const loadHomeScreen = (cb) => {
  return { type: "HOME_SCREEN_DATA_ATTEMPT", cb };
};

const Home = () => {
  const action = useDispatch();
  const store = useSelector((state) => state);
  const [home, setHome] = useState({});
  const [slider, setSlider] = useState([]);
  const [bike, setBike] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [blog, setBlog] = useState([]);
  useEffect(() => {
    action(
      loadHomeScreen((detail) => {
        setHome(detail.home);
        setSlider(detail.sliders);
        setBike(detail.bikes);
        setCustomer(detail.customers);
        setBlog(detail.blogs);
      })
    );
  }, []);

  return (
    <FrontLayout>
      {Object.values(home).length > 0 && <PageVideo home={home} />}
      {slider.length > 0 && <HomeSlider slider={slider} />}
      {bike.length > 0 && <HomeBikes bikes={bike} />}
      {customer.length > 0 && <HomeCustomer customers={customer} />}
      {blog.length > 0 && <Rides blogs={blog} />}
      {store.bike.isFetching ? (
        <div className="view-more">
          <p>
            <img src={loading} />
          </p>
        </div>
      ) : (
        ""
      )}
    </FrontLayout>
  );
};

export default Home;
