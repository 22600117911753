import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faUserCircle,
  faPenToSquare,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux/es/exports";
import axios from "axios";
import { base_url } from "../../../constant";
import Updateprofile from "./updateprofile";
import Password from "./password";

const uploadImage = (formData) => {
  return { type: "PROFILE_UPLOAD_DATA_ATTEMPT", payload: formData };
};

function Userprofile() {
  const [changePassword, setchangePassword] = useState(false);
  const store = useSelector((state) => {
    return { profile: state.profile };
  });

  const action = useDispatch();
  const fileField = useRef(null);
  // console.log("Email:", props.profile.email);

  function onChangeImg(event) {
    // setName(URL.createObjectURL(event.target.files[0]));
    const formData = new FormData();
    formData.append("files", event.target.files[0]);
    let url = base_url + "upload_file";
    console.log("FormData", formData);
    action(uploadImage(formData));
  }

  return (
    <div className="page_wrapper">
      <div className="page-header">
        <h1>Profile </h1>
        {/* {profile} */}
      </div>

      <div className="row">
        <div className="col-sd-12 col-md-4">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <div className="profile-picture">
                  {store.profile.response.users.image_url ? (
                    <img
                      src={store.profile.response.users.image_url}
                      alt="File preview"
                    />
                  ) : (
                    ""
                  )}
                  <p>
                    <span
                      href="#"
                      onClick={() => fileField.current.click()}
                      className="btn btn-default"
                    >
                      <FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon>
                    </span>
                  </p>
                </div>

                <div style={{ display: "none" }}>
                  <label className="text-white">Select File</label>
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    ref={fileField}
                    onChange={(e) => onChangeImg(e)}
                  />
                </div>

                <div className="profile-detail">
                  <p>
                    <FontAwesomeIcon icon={faUserCircle}></FontAwesomeIcon>
                    <strong>{store.profile.response.users.name}</strong>
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                    <strong>{store.profile.response.users.email}</strong>
                  </p>
                  {!changePassword ? (
                    <p
                      onClick={() => setchangePassword(true)}
                      className="link_change_password"
                    >
                      <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                      <strong> Change Password </strong>
                    </p>
                  ) : (
                    <p
                      onClick={() => setchangePassword(false)}
                      className="link_change_password"
                    >
                      <FontAwesomeIcon icon={faUserCircle}></FontAwesomeIcon>
                      <strong> Change Profile </strong>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sd-12 col-md-6">
          {!changePassword ? <Updateprofile /> : <Password />}
        </div>
      </div>
    </div>
  );
}

export default Userprofile;
