import Footer from "./common/footer";
import Header from "./common/header";
import Leftbar from "./common/leftbar";
import Layout from "../../layout/layout";
import Dashboard from "./dashboard";
function Admindashboard() {
  return (
    <Layout>
      <Dashboard />
    </Layout>
  );
}

export default Admindashboard;
