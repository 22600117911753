import Layout from "../../../layout/layout";
import AddForm from "./addForm";

const AddBlog = () => {
  return (
    <Layout>
      <AddForm />
    </Layout>
  );
};

export default AddBlog;
