import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState, Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const submitModel = (formData, id) => {
  return { type: "PAGE_EDIT_DATA_ATTEMPT", payload: formData, pageid: id };
};
const getDetail = (pageid, cb = false) => {
  return {
    type: "PAGE_DETAIL_DATA_ATTEMPT",
    payload: { pageid: pageid },
    cb,
  };
};
const EditPageForm = () => {
  const { id } = useParams();
  //   alert(id);
  const store = useSelector((state) => state);

  const action = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [pageVideo, setPageVideo] = useState("");
  const [videoFile, setVideoFile] = useState("");
  const [status, setStatus] = useState("Active");

  const [nameError, setNameError] = useState("");
  const [detailError, setDetailError] = useState("");

  useEffect(() => {
    action(
      getDetail(id, (detail) => {
        setName(detail.title);
        setDetail(detail.detail);
        setImage(detail.thumbnail);
        setPageVideo(detail.video);
        setStatus(detail.status);
      })
    );
  }, []);

  useEffect(() => {
    if (store.page.isSuccess) {
      toast("Success!");
      navigate("/admin/page");
    }
  }, [store.page.isSuccess]);

  const onChangeImg = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageFile(event.target.files[0]);
  };

  const onChangeVideo = (event) => {
    setPageVideo(URL.createObjectURL(event.target.files[0]));
    setVideoFile(event.target.files[0]);
  };

  const handleForm = (event) => {
    event.preventDefault();
    let error = false;
    if (name == "") {
      setNameError("Page name is required.");
      error = true;
    }

    if (detail == "") {
      setDetailError("Page detail is required.");
      error = true;
    }

    if (error == true) return false;

    const formData = new FormData();
    formData.append("files", imageFile);
    formData.append("video", videoFile);
    formData.append("name", name);
    formData.append("detail", detail);
    formData.append("status", status);
    action(submitModel(formData, id));
  };

  return (
    <div className="page_wrapper">
      <div className="page-header">
        <h1>Edit Page</h1>
      </div>

      <form onSubmit={(e) => handleForm(e)}>
        <div className="form-group row mb-30">
          <label htmlFor="bike_model" className="col-md-3">
            Page Name
          </label>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              id="name"
              aria-describedby="emailHelp"
              value={name}
              placeholder="Page title"
              onChange={(e) => setName(e.target.value)}
            />
            <p className="error"> {nameError}</p>
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="detail" className="col-md-3">
            Detail
          </label>
          <div className="col-md-6">
            <CKEditor
              editor={ClassicEditor}
              data={detail}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDetail(data);
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="image" className="col-md-3">
            Image
          </label>
          <div className="col-md-6">
            <input
              type="file"
              className="form-control-file"
              id="image"
              name="image"
              onChange={(e) => onChangeImg(e)}
              accept="image/*"
            />
            <p className="error"></p>
          </div>
          <div className="col-md-3">
            {image ? (
              <>
                <img src={image} className="field_image" />
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    setImage("");
                  }}
                >
                  Remove
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="image" className="col-md-3">
            Video
          </label>
          <div className="col-md-6">
            <input
              type="file"
              className="form-control-file"
              id="video"
              name="video"
              onChange={(e) => onChangeVideo(e)}
              accept="video/*"
            />
            <p className="error"></p>
          </div>

          <div className="col-md-3">
            {pageVideo ? (
              <>
                <video width="100%" key={pageVideo} controls>
                  <source src={pageVideo} type="video/mp4" />
                </video>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    setPageVideo("");
                  }}
                >
                  Remove
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="status" className="col-md-3">
            Status
          </label>
          <div className="col-md-6">
            <select
              value={status}
              className="form-control"
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="form-group row mb-30">
          <label className="col-md-3"></label>
          <div className="col-md-6">
            <button type="submit" className="btn btn-primary mr-30">
              Submit
            </button>
            <Link to="/admin/page" className="btn btn-danger">
              Back
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditPageForm;
