import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import img from "../../../assets/imgs/royal-enfield-classic-350.jpg";
import halcyon_green from "../../../assets/imgs/halcyon-green.png";
import halcyon_black from "../../../assets/imgs/halycon-black.png";
import halcyon_grey from "../../../assets/imgs/halycon-grey.png";
import redditch_green from "../../../assets/imgs/redditch-green.png";
import redditch_red from "../../../assets/imgs/redditch-red.png";
import redditch_grey from "../../../assets/imgs/redditch-grey.png";

import signals_marsh_grey from "../../../assets/imgs/signals-marsh-grey.png";
import signals_sandstorm from "../../../assets/imgs/signals-sandstorm.png";

const Colors = (props) => {
  // console.log("colors", props.colors);
  const colors = props.colors;
  const images = [];
  colors.map((color, index) => {
    images.push({
      original: color.bike_photo,
      thumbnail: color.bike_thumbnail,
      //  thumbnailLabel: "ENGINE",
      thumbnailHeight: "100",
      description: color.detail,
      thumbnailClass: "image-gallery-thumbnail-color",
    });
  });
  const images1 = [
    {
      original: img,
      thumbnail: signals_marsh_grey,
      //  thumbnailLabel: "ENGINE",
      thumbnailHeight: "100",
      description: "Starts from: ₹ 196,125",
      thumbnailClass: "image-gallery-thumbnail-color",
    },
    {
      original: img,
      thumbnail: signals_sandstorm,
      //    thumbnailLabel: "BRAKE",
      thumbnailHeight: "100",
      description: "Starts from: ₹ 196,125",
      thumbnailClass: "image-gallery-thumbnail-color",
    },
    {
      original: img,
      thumbnail: redditch_grey,
      //   thumbnailLabel: "HEADLIGHT",
      thumbnailHeight: "100px",
      description: "Starts from: ₹ 196,125",
      thumbnailClass: "image-gallery-thumbnail-color",
    },
    {
      original: img,
      thumbnail: redditch_red,
      // thumbnailLabel: "CONSOLE",
      thumbnailHeight: "100",
      description: "Starts from: ₹ 196,125",
      thumbnailClass: "image-gallery-thumbnail-color",
    },
    {
      original: img,
      thumbnail: redditch_green,
      //  thumbnailLabel: "FUEL TANK",
      thumbnailHeight: "100",
      description: "Starts from: ₹ 196,125",
      thumbnailClass: "image-gallery-thumbnail-color",
    },
    {
      original: img,
      thumbnail: halcyon_grey,
      // thumbnailLabel: "SEAT",
      thumbnailHeight: "100px",
      description: "Starts from: ₹ 196,125",
      thumbnailClass: "image-gallery-thumbnail-color",
    },
    {
      original: img,
      thumbnail: halcyon_green,
      //  thumbnailLabel: "SEAT",
      thumbnailHeight: "100px",
      description: "Starts from: ₹ 196,125",
      thumbnailClass: "image-gallery-thumbnail-color",
    },
    {
      original: img,
      thumbnail: halcyon_black,
      //  thumbnailLabel: "SEAT",
      thumbnailHeight: "100px",
      description: "Starts from: ₹ 196,125",
      thumbnailClass: "image-gallery-thumbnail-color",
    },
  ];
  return (
    <>
      <div className="section-title">Colours</div>
      <ImageGallery
        items={images}
        thumbnailPosition="right"
        autoPlay={false}
        //   disableThumbnailScroll={true}
        showPlayButton={false}
        //   thumbnailHeight="100%"
      />
    </>
  );
};

export default Colors;
