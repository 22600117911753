import { ofType } from "redux-observable";
import { catchError, switchMap, mergeMap, delay } from "rxjs/operators";
import { from, of } from "rxjs";
import { base_url } from "../constant";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export const fetchModelsApi = async (action) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "models", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      page: action.payload.page,
    },
  });
  return res;
};

export const fetchModelEpic = (action$) =>
  action$.pipe(
    ofType("MODEL_FETCHING_DATA_ATTEMPT", "MODEL_DELETE_DATA_SUCCESS"),
    mergeMap((action) =>
      from(fetchModelsApi(action)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "MODEL_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "MODEL_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const addModelsApi = async (formData) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "model/add", formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const addModelEpic = (action$) =>
  action$.pipe(
    ofType("MODEL_ADD_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(addModelsApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "MODEL_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "MODEL_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchDetailModelsApi = async (payload) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "model/" + payload.modelid, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      // modelid: payload.modelid,
    },
  });
  return res;
};

export const fetchDetailModelEpic = (action$) =>
  action$.pipe(
    ofType("MODEL_DETAIL_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchDetailModelsApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          if (action.cb) {
            action.cb(response?.data);
          }
          return of({
            type: "MODEL_DETAIL_DATA_SUCCESS",
            payload: response?.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "MODEL_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const editModelsApi = async (formData, modelid) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "model/edit/" + modelid, formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const editModelEpic = (action$) =>
  action$.pipe(
    ofType("MODEL_EDIT_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(editModelsApi(action.payload, action.modelid)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "MODEL_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "MODEL_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const deleteModelsApi = async (modelid) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(
    base_url + "model/delete/" + modelid,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res;
};

export const deleteModelEpic = (action$) =>
  action$.pipe(
    ofType("MODEL_DELETE_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(deleteModelsApi(action.payload.modelid)).pipe(
        mergeMap((response) => {
          toast("Success!");
          console.log("response", response);
          return of({
            type: "MODEL_DELETE_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "MODEL_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );
