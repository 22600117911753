import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const saveProile = (formData) => {
  return { type: "PROFILE_UPDATE_DATA_ATTEMPT", payload: formData };
};
const loadProfile = (cb) => {
  return { type: "PROFILE_FETCHING_DATA_ATTEMPT", cb };
};

const Updateprofile = () => {
  const store = useSelector((state) => state);
  useEffect(() => {
    actions(
      loadProfile((detail) => {
        setPname(detail.users.name);
        setPemail(detail.users.email);
        setEmail(detail.setting.email);
        setConact1(detail.setting.contact_no1);
        setConact2(detail.setting.contact_no2);
        setFacebookurl(detail.setting.facebook_url);
        setTwitterurl(detail.setting.twitter_url);
        setYoutubeurl(detail.setting.youtube_url);
        setInstaurl(detail.setting.instagram_url);
        setSite_title(detail.setting.site_title);
        setMeta_desc(detail.setting.meta_desc);
        setMeta_keyword(detail.setting.meta_keyword);
        setImage(detail.setting.logo_thumbnail);
      })
    );
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (store.profile.isSuccess) {
      toast("Success!");
      // navigate("/admin/profile");
    }
  }, [store.profile.isSuccess]);

  // console.log("store", store);

  const [pname, setPname] = useState("");
  const [pemail, setPemail] = useState("");
  const [email, setEmail] = useState("");
  const [contact1, setConact1] = useState("");
  const [contact2, setConact2] = useState("");
  const [facebookurl, setFacebookurl] = useState("");
  const [twitterurl, setTwitterurl] = useState("");
  const [youtubeurl, setYoutubeurl] = useState("");
  const [instaurl, setInstaurl] = useState("");
  const [site_title, setSite_title] = useState("");
  const [meta_desc, setMeta_desc] = useState("");
  const [meta_keyword, setMeta_keyword] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");

  const [pnameError, setPnameError] = useState("");
  const [pemailError, setPemailError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contact1Error, setConact1Error] = useState("");
  const [facebookurlError, setFacebookurlError] = useState("");
  const [twitterurlError, setTwitterurlError] = useState("");
  const [youtubeurlError, setYoutubeurlError] = useState("");
  const [instaurlError, setInstaurlError] = useState("");
  const [site_titleError, setSite_titleError] = useState();

  const actions = useDispatch();

  const changeProfile = (e) => {
    e.preventDefault();
    let error = false;
    if (pname == "") {
      setPnameError("Name is required.");
      error = true;
    }

    if (pemail == "") {
      setPemailError("Email is required.");
      error = true;
    }

    if (email == "") {
      setEmailError("Email is required.");
      error = true;
    }

    if (contact1 == "") {
      setConact1Error("Contact number is required.");
      error = true;
    }

    if (facebookurl == "") {
      setFacebookurlError("Facebook url is required.");
      error = true;
    }

    if (twitterurl == "") {
      setTwitterurlError("Twitter url is required.");
      error = true;
    }

    if (youtubeurl == "") {
      setYoutubeurlError("Youtube url is required.");
      error = true;
    }

    if (instaurl == "") {
      setInstaurlError("Instagram url is required.");
      error = true;
    }

    if (site_title == "") {
      setSite_titleError("Site title is required.");
    }

    if (error == true) return false;

    const formData = new FormData();
    formData.append("email", pemail);
    formData.append("name", pname);
    formData.append("site_email", email);
    formData.append("contact1", contact1);
    formData.append("contact2", contact2);
    formData.append("facebookurl", facebookurl);
    formData.append("twitterurl", twitterurl);
    formData.append("youtubeurl", youtubeurl);
    formData.append("instaurl", instaurl);
    formData.append("site_title", site_title);
    formData.append("meta_desc", meta_desc);
    formData.append("meta_keyword", meta_keyword);
    formData.append("files", imageFile);
    actions(saveProile(formData));
  };

  const onChangeImg = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageFile(event.target.files[0]);
  };

  return (
    <div className="card-body">
      <form onSubmit={(e) => changeProfile(e)}>
        <h3>User Detail</h3>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            aria-describedby="emailHelp"
            placeholder="Enter name"
            value={pname}
            onChange={(e) => setPname(e.target.value)}
          />
          <p className="error">{pnameError}</p>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email address</label>
          <input
            type="text"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            value={pemail}
            onChange={(e) => setPemail(e.target.value)}
          />
          <p className="error">{pemailError}</p>
          <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small>
        </div>

        <h3>Site Settings</h3>

        <div className="form-group ">
          <label htmlFor="image" className="col-md-3">
            Logo
          </label>
          <div className="col-md-6">
            <input
              type="file"
              className="form-control-file"
              id="image"
              name="image"
              onChange={(e) => onChangeImg(e)}
              accept="image/*"
            />
            <img src={image} className="field_image" />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="email">Site Title</label>
          <input
            type="text"
            className="form-control"
            id="site_title"
            aria-describedby="emailHelp"
            placeholder="Enter Site title"
            value={site_title}
            onChange={(e) => setSite_title(e.target.value)}
          />
          <p className="error">{site_titleError}</p>
        </div>

        <div className="form-group">
          <label htmlFor="email">Meta description</label>
          <input
            type="text"
            className="form-control"
            id="meta_desc"
            aria-describedby="emailHelp"
            placeholder="Enter meta description"
            value={meta_desc}
            onChange={(e) => setMeta_desc(e.target.value)}
          />
          <p className="error"></p>
        </div>

        <div className="form-group">
          <label htmlFor="email">Meta Keyword</label>
          <input
            type="text"
            className="form-control"
            id="meta_keyword"
            aria-describedby="emailHelp"
            placeholder="Enter meta keywords"
            value={meta_keyword}
            onChange={(e) => setMeta_keyword(e.target.value)}
          />
          <p className="error"></p>
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <p className="error">{emailError}</p>
        </div>

        <div className="form-group">
          <label htmlFor="email">Contact No</label>
          <input
            type="text"
            className="form-control"
            id="contact1"
            aria-describedby="emailHelp"
            placeholder="Contact No"
            value={contact1}
            onChange={(e) => setConact1(e.target.value)}
          />
          <p className="error">{contact1Error}</p>
        </div>

        <div className="form-group">
          <label htmlFor="email">Other Contact No</label>
          <input
            type="text"
            className="form-control"
            id="contact2"
            aria-describedby="emailHelp"
            placeholder="Other contact no"
            value={contact2}
            onChange={(e) => setConact2(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Facebook Url</label>
          <input
            type="text"
            className="form-control"
            id="facebook_url"
            aria-describedby="emailHelp"
            placeholder="Facebook Url"
            value={facebookurl}
            onChange={(e) => setFacebookurl(e.target.value)}
          />
          <p className="error">{facebookurlError}</p>
        </div>

        <div className="form-group">
          <label htmlFor="email">Twitter Url</label>
          <input
            type="text"
            className="form-control"
            id="twitter_url"
            aria-describedby="emailHelp"
            placeholder="Twitter Url"
            value={twitterurl}
            onChange={(e) => setTwitterurl(e.target.value)}
          />
          <p className="error">{twitterurlError}</p>
        </div>

        <div className="form-group">
          <label htmlFor="email">Youtube Url</label>
          <input
            type="text"
            className="form-control"
            id="youtube_url"
            aria-describedby="emailHelp"
            placeholder="Youtube Url"
            value={youtubeurl}
            onChange={(e) => setYoutubeurl(e.target.value)}
          />
          <p className="error">{youtubeurlError}</p>
        </div>

        <div className="form-group">
          <label htmlFor="email">Instagram Url</label>
          <input
            type="text"
            className="form-control"
            id="instagram_url"
            aria-describedby="emailHelp"
            placeholder="Instagram Url"
            value={instaurl}
            onChange={(e) => setInstaurl(e.target.value)}
          />
          <p className="error">{instaurlError}</p>
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
        <p></p>
      </form>
    </div>
  );
};

export default Updateprofile;
