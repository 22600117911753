import React from "react";
import FrontLayout from "./layout/layout";
import img from "../../assets/imgs/royal-enfield-classic-350.jpg";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const getDetail = (slug, cb = false) => {
  return {
    type: "BLOG_DETAIL_DATA_SLUG_ATTEMPT",
    payload: { slug: slug },
    cb,
  };
};

const BlogDetail = () => {
  const { slug } = useParams();
  const action = useDispatch();
  const store = useSelector((state) => state);
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");
  const [image, setImage] = useState("");
  const [pageVideo, setPageVideo] = useState("");
  useEffect(() => {
    action(
      getDetail(slug, (detail) => {
        setName(detail.title);
        setDetail(detail.detail);
        setImage(detail.photo);
        setPageVideo(detail.video);
      })
    );
  }, []);
  return (
    <FrontLayout>
      <Helmet>
        <title>{name}</title>
        <meta name="description" content={detail} />

        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={name} />
        <meta property="og:description" content={detail} />
        <meta property="og:image" content={image} />
      </Helmet>
      <Breadcrumb>
        <BreadcrumbItem>Home</BreadcrumbItem>
        <BreadcrumbItem>Blogs</BreadcrumbItem>
        <BreadcrumbItem>{name}</BreadcrumbItem>
      </Breadcrumb>
      <nav class="menu-bar">
        <span class="stick right share-icon">
          {" "}
          <FacebookShareButton url={window.location.href} quote={name}>
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>
          <TwitterShareButton url={window.location.href} title={name}>
            <TwitterIcon size={40} round={true} />
          </TwitterShareButton>
          <WhatsappShareButton url={window.location.href} title={name}>
            <WhatsappIcon size={40} round={true} />
          </WhatsappShareButton>
          <LinkedinShareButton url={window.location.href} title={name}>
            <LinkedinIcon size={40} round={true} />
          </LinkedinShareButton>
        </span>
      </nav>
      <div className="page-wrapper">
        <div className="page-container">
          {image ? <img src={image} /> : ""}
          <h3>{name}</h3>
          <div dangerouslySetInnerHTML={{ __html: detail }}></div>
          <div className="page-video-wrapper">
            {pageVideo ? (
              <video width="70%" key={pageVideo} controls>
                <source src={pageVideo} type="video/mp4" />
              </video>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default BlogDetail;
