import "../../../assets/css/Style.css";
import "../../../assets/css/Responsive.css";
import Footer from "../common/footer";
import Header from "../common/header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const loadSettings = () => {
  return { type: "FRONT_SETTING_DATA_ATTEMPT" };
};

const FrontLayout = (prop) => {
  const action = useDispatch();
  const store = useSelector((state) => state);

  useEffect(() => {
    action(loadSettings());
  }, []);
  return (
    <>
      <Helmet>
        <title>{store.front.setting.site_title}</title>
        <meta name="description" content={store.front.setting.meta_desc} />
        <meta name="keywords" content={store.front.setting.meta_keyword}></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>

        <link rel="icon" href={store.front.setting.image1} />

        <link rel="apple-touch-icon" href={store.front.setting.image1} />

        <meta property="og:url" content={store.front.setting.url} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={store.front.setting.site_title} />
        <meta
          property="og:description"
          content={store.front.setting.meta_desc}
        />
        <meta property="og:image" content={store.front.setting.home?.photo} />
      </Helmet>
      <Header setting={store.front.setting} />

      <div className="wrapper-body">{prop.children}</div>

      <Footer setting={store.front.setting} />
    </>
  );
};

export default FrontLayout;
