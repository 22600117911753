import Login from "./components/admin/login";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Admindashboard from "./components/admin/admindashboard";
import Bikes from "./components/admin/bikes";
import Myprofile from "./components/admin/profile";
import Parts from "./components/admin/parts";
import Contacts from "./components/admin/contacts";
import Orders from "./components/admin/orders";
import Model from "./components/admin/models/model";
import AddModel from "./components/admin/models/add";
import EditModel from "./components/admin/models/edit";
import { ToastContainer, toast } from "react-toastify";
import AddBike from "./components/admin/bikes/addBike";
import EditBike from "./components/admin/bikes/editBike";
import BikeColors from "./components/admin/bikes/bikeColors";
import AddBikeColors from "./components/admin/bikes/addBikeColor";
import EditBikeColors from "./components/admin/bikes/editBikeColor";
import BikePhotos from "./components/admin/bikes/bikePhotos";
import AddBikePhoto from "./components/admin/bikes/addBikePhoto";
import EditBikePhoto from "./components/admin/bikes/editBikePhoto";
import Home from "./components/front/home";
import BikeDetail from "./components/front/details/bikeDetail";
import ProtectedRoute from "./components/admin/protectedRoute";
import Aboutus from "./components/front/aboutus";
import Blog from "./components/front/blog";
import Contact from "./components/front/contact";
import Page from "./components/admin/page";
import AddPage from "./components/admin/pages/AddPage";
import EditPage from "./components/admin/pages/EditPage";
import AdminBlog from "./components/admin/blog";
import EditBlog from "./components/admin/blogs/edit";
import AddBlog from "./components/admin/blogs/add";
import Customer from "./components/admin/customer";
import AddCustomer from "./components/admin/customers/add";
import EditCustomer from "./components/admin/customers/edit";
import CustomerList from "./components/front/customer";
import BikeList from "./components/front/bikelist";
import Privacy from "./components/front/privacy";
import ImportPrivacy from "./components/front/importPrivacy";
import TermsConditions from "./components/front/termsConditions";
import Cookies from "./components/front/cookies";
import BlogDetail from "./components/front/blogDetail";
//localStorage.getItem("ramro_token")
function App() {
  return (
    <div className="App">
      <div>
        <BrowserRouter>
          <Routes>
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <Admindashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/profile"
              element={
                <ProtectedRoute>
                  <Myprofile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/page"
              element={
                <ProtectedRoute>
                  <Page />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/page/add"
              element={
                <ProtectedRoute>
                  <AddPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/page/:id/edit"
              element={
                <ProtectedRoute>
                  <EditPage />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/blogs"
              element={
                <ProtectedRoute>
                  <AdminBlog />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/blog/add"
              element={
                <ProtectedRoute>
                  <AddBlog />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/blog/:id/edit"
              element={
                <ProtectedRoute>
                  <EditBlog />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/models"
              element={
                <ProtectedRoute>
                  <Model />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/model/add"
              element={
                <ProtectedRoute>
                  <AddModel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/model/:id/edit"
              element={
                <ProtectedRoute>
                  <EditModel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/bikes"
              element={
                <ProtectedRoute>
                  <Bikes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/bike/add"
              element={
                <ProtectedRoute>
                  <AddBike />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/bike/:id/edit"
              element={
                <ProtectedRoute>
                  <EditBike />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/bike/colors"
              element={
                <ProtectedRoute>
                  <BikeColors />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/bike/add-color"
              element={
                <ProtectedRoute>
                  <AddBikeColors />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/bike/:id/color"
              element={
                <ProtectedRoute>
                  <EditBikeColors />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/bike/photos"
              element={
                <ProtectedRoute>
                  <BikePhotos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/bike/add-photo"
              element={
                <ProtectedRoute>
                  <AddBikePhoto />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/bike/:id/photo"
              element={
                <ProtectedRoute>
                  <EditBikePhoto />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/customers"
              element={
                <ProtectedRoute>
                  <Customer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/customer/add"
              element={
                <ProtectedRoute>
                  <AddCustomer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/customer/:id/edit"
              element={
                <ProtectedRoute>
                  <EditCustomer />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/parts"
              element={
                <ProtectedRoute>
                  <Parts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/orders"
              element={
                <ProtectedRoute>
                  <Orders />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/contacts"
              element={
                <ProtectedRoute>
                  <Contacts />
                </ProtectedRoute>
              }
            />
            <Route exact path="/admin/login" element={<Login />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/motorcycles" element={<BikeList />} />
            <Route path="/motorcycles/:slug" element={<BikeDetail />} />
            <Route path="/about-us" element={<Aboutus />} />
            <Route exact path="/blogs" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogDetail />} />
            <Route path="/customers" element={<CustomerList />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/import-policy" element={<ImportPrivacy />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/cookies" element={<Cookies />} />
          </Routes>
        </BrowserRouter>
      </div>
      <ToastContainer />
    </div>
  );
}

export default App;
