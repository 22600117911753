let initialState = {
  response: {
    blogs: [],
    has_next_page: true,
  },
  isFetching: false,
  isError: false,
  isSuccess: false,
  detail: {},
};

export default function blogReducer(state = initialState, action) {
  switch (action.type) {
    case "FRONT_BLOG_FETCHING_DATA_ATTEMPT":
      return {
        ...state,
        isError: false,
        isFetching: true,
        isSuccess: false,
      };
    case "BLOG_FETCHING_DATA_SUCCESS":
      // console.log("blogs", action.payload);
      return {
        ...state,
        response: {
          blogs:
            action.payload.current_page > 1
              ? [...state.response.blogs, ...action.payload.blogs]
              : [...action.payload.blogs],
          has_next_page: action.payload.has_next_page,
        },
        isFetching: false,
        isError: false,
        isSuccess: false,
      };
    case "BLOG_FETCHING_DATA_FAILURE":
      return {
        ...state,
        isError: action.payload,
        isFetching: false,
        isSuccess: false,
      };

    case "BLOG_ADD_DATA_SUCCESS":
      return {
        ...state,
        isError: action.payload,
        isFetching: false,
        isSuccess: true,
      };

    case "BLOG_DETAIL_DATA_SUCCESS":
      return {
        ...state,
        isError: false,
        isFetching: false,
        isSuccess: false,
        detail: action.payload,
      };

    case "BLOG_RESET_ALL_LOADING":
      return {
        ...state,
        isFetching: false,
        isError: false,
        isSuccess: false,
      };
    default:
      return state;
  }
}
