import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import img from "../../../assets/imgs/royal-enfield-classic-350.jpg";

const Photos = (props) => {
  const photos = props.photos;
  const images = [];
  photos.map((photo, index) => {
    images.push({
      original: photo.bike_photo,
      thumbnail: photo.bike_thumbnail,
      thumbnailLabel: photo.title,
      thumbnailHeight: "60",
      description: photo.detail,
    });
  });

  return (
    <ImageGallery
      items={images}
      thumbnailPosition="left"
      autoPlay={true}
      //   disableThumbnailScroll={true}
      showPlayButton={false}
      //   thumbnailHeight="100%"
    />
  );
};

export default Photos;
