import { ofType } from "redux-observable";
import { catchError, switchMap, mergeMap, delay } from "rxjs/operators";
import { from, of } from "rxjs";
import { base_url } from "../constant";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export const fetchCustomersApi = async (action) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "customers", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      page: action.payload.page,
      keyword: action.payload.keyword,
    },
  });
  return res;
};

export const fetchCustomerEpic = (action$) =>
  action$.pipe(
    ofType("CUSTOMER_FETCHING_DATA_ATTEMPT", "CUSTOMER_DELETE_DATA_SUCCESS"),
    mergeMap((action) =>
      from(fetchCustomersApi(action)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "CUSTOMER_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "CUSTOMER_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const addCustomersApi = async (formData) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "customer/add", formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const addCustomerEpic = (action$) =>
  action$.pipe(
    ofType("CUSTOMER_ADD_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(addCustomersApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "CUSTOMER_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "CUSTOMER_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchDetailCustomerApi = async (payload) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "customer/" + payload.customerid, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      // modelid: payload.modelid,
    },
  });
  return res;
};

export const fetchDetailCustomerEpic = (action$) =>
  action$.pipe(
    ofType("CUSTOMER_DETAIL_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchDetailCustomerApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          if (action.cb) {
            action.cb(response?.data);
          }
          return of({
            type: "CUSTOMER_DETAIL_DATA_SUCCESS",
            payload: response?.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "CUSTOMER_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const editCustomerApi = async (formData, customerid) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(
    base_url + "customer/edit/" + customerid,
    formData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res;
};

export const editCustomerEpic = (action$) =>
  action$.pipe(
    ofType("CUSTOMER_EDIT_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(editCustomerApi(action.payload, action.customerid)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "CUSTOMER_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "CUSTOMER_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const deleteCustomerApi = async (customerid) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(
    base_url + "customer/delete/" + customerid,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res;
};

export const deleteCustomerEpic = (action$) =>
  action$.pipe(
    ofType("CUSTOMER_DELETE_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(deleteCustomerApi(action.payload.customerid)).pipe(
        mergeMap((response) => {
          toast("Success!");
          console.log("response", response);
          return of({
            type: "CUSTOMER_DELETE_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "CUSTOMER_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchFrontCustomersApi = async (action) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "front/customers", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      page: action.payload.page,
      keyword: action.payload.keyword,
    },
  });
  return res;
};

export const fetchFrontCustomerEpic = (action$) =>
  action$.pipe(
    ofType("FRONT_CUSTOMER_FETCHING_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchFrontCustomersApi(action)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "CUSTOMER_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "CUSTOMER_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );
