import Layout from "../../layout/layout";

import List from "./pages/list";

function Page() {
  return (
    <Layout>
      <List />
    </Layout>
  );
}

export default Page;
