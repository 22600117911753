import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPlus,
  faTrashCan,
  faTruckLoading,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { confirm } from "react-confirm-box";
import loadingImage from "../../../assets/imgs/loading.gif";

const loadBikes = (page) => {
  return { type: "BIKE_FETCHING_DATA_ATTEMPT", payload: { page } };
};

const deleteBikeRecord = (bikeid) => {
  return { type: "BIKE_DELETE_DATA_ATTEMPT", payload: { bikeid } };
};
const List = () => {
  const action = useDispatch();
  const store = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    action(loadBikes(page));
  }, [page]);

  const loadMoreBike = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && store.model.response.has_next_page) {
      setPage(page + 1);
    }
  };

  const deleteBike = async (modelid) => {
    const result = await confirm("Are you sure to delete this Bike?");
    if (result) {
      action(deleteBikeRecord(modelid));
    }
  };
  return (
    <div className="page_wrapper">
      <div className="page-header">
        <h1>
          Bikes
          <Link className="btn btn-primary float-right" to="/admin/bike/add">
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add
          </Link>
        </h1>
      </div>

      <div className="row">
        <div className="col-sd-12 col-md-12">
          <div style={{ overflowX: "auto" }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Bike Name</th>
                  <th scope="col">Image</th>
                  <th>Status</th>
                  <th scope="col"> Action </th>
                </tr>
              </thead>
              <tbody>
                {store.bike.response.bikes.length > 0 ? (
                  store.bike.response.bikes.map((bike, key) => {
                    return (
                      <tr key={bike.id}>
                        <td scope="col">#{key + 1}</td>
                        <td scope="col">{bike.name}</td>
                        <td scope="col">
                          <img src={bike.thumbnail} className="img-100" />
                        </td>
                        <td>
                          <span className={bike.status}>{bike.status}</span>
                        </td>
                        <td scope="col">
                          <div className="action">
                            <Link to={`/admin/bike/${bike.id}/edit`}>
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                              ></FontAwesomeIcon>
                            </Link>
                            {/* <FontAwesomeIcon
                            className="link_pointer"
                            icon={faTrashCan}
                            onClick={() => deleteBike(bike.id)}
                          ></FontAwesomeIcon> */}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td scope="row" colSpan={5} style={{ textAlign: "center" }}>
                      {loading ? <img src={loadingImage} /> : "No Models found"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
