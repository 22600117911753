import Layout from "../../layout/layout";

import List from "./customers/list";

function Customer() {
  return (
    <Layout>
      <List />
    </Layout>
  );
}

export default Customer;
