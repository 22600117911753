import { Link, Navigate, useOutlet } from "react-router-dom";
const ProtectedRoute = ({ children }) => {
  if (!localStorage.getItem("ramro_token")) {
    // user is not authenticated
    return <Navigate to="/admin/login" />;
  }
  return children;
};

export default ProtectedRoute;
