import { ofType } from "redux-observable";
import { catchError, switchMap, mergeMap, delay } from "rxjs/operators";
import { from, of } from "rxjs";
import { base_url } from "../constant";
import axios from "axios";

export const profileApi = async () => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "profile", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      search: "hello",
    },
  });
  return res;
};

export const profileImgUploadApi = async (formData) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "upload_file", formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const loginEpic = (action$) =>
  action$.pipe(
    ofType(
      "PROFILE_FETCHING_DATA_ATTEMPT",
      "PROFILE_UPLOAD_DATA_SUCCESS",
      "PROFILE_UPDATE_DATA_SUCCESS"
    ),
    mergeMap((action) =>
      from(profileApi()).pipe(
        mergeMap((response) => {
          //  console.log(response);
          if (action.cb) {
            action.cb(response?.data);
          }
          return of({
            type: "PROFILE_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "PROFILE_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const uploadProfileImgEpic = (action$) =>
  action$.pipe(
    ofType("PROFILE_UPLOAD_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(profileImgUploadApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "PROFILE_UPLOAD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "PROFILE_UPLOAD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const profileUpdateApi = async (formData) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "profile", formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const updateProfileEpic = (action$) =>
  action$.pipe(
    ofType("PROFILE_UPDATE_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(profileUpdateApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "PROFILE_UPDATE_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "PROFILE_UPLOAD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const passwordUpdateApi = async (formData) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "user_update_password", formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const updatePasswordEpic = (action$) =>
  action$.pipe(
    ofType("PASSWORD_UPDATE_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(passwordUpdateApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "PASSWORD_UPDATE_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "PASSWORD_UPDATE_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );
