import { Link } from "react-router-dom";
import img1 from "../../../assets/imgs/blog1.jpg";
import img2 from "../../../assets/imgs/blog2.jpg";
import img3 from "../../../assets/imgs/blog3.jpg";
import img4 from "../../../assets/imgs/blog4.jpg";
import img5 from "../../../assets/imgs/blog5.png";
import img6 from "../../../assets/imgs/blog6.png";

import { useState } from "react";
import { useEffect } from "react";

const Rides = (props) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs(props.blogs);
  }, [props.blogs]);

  const regex = /(<([^>]+)>)/gi;

  return (
    <>
      <div className="section-title ride-title">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
          width="50"
          height="50"
        >
          <path d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160c0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32c0-52.2 25-98.6 63.7-127.8l15.4 28.6C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128H480c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H459.6c-7.5 0-14.7 2.6-20.5 7.4L391.7 78.9l-14-26c-7-12.9-20.5-21-35.2-21H280zM462.7 311.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zM187.3 376c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40h66.4C242.5 268.8 190.5 224 128 224C57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104H187.3zM128 384c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32z" />
        </svg>
        <span>Rides/Blogs</span>
      </div>
      <div className="wrapper-ride">
        <div className="container-ride">
          <div className="wrapper-bikelists">
            {blogs.length > 0
              ? blogs.map((blog, index) => {
                  return (
                    <div className="ride-wrapper" key={index}>
                      <div className="ride">
                        <Link to={`/blog/${blog.slug}`}>
                          {" "}
                          <img src={blog.thumbnail} className="blog-img" />
                          <div className="content">
                            <h5>{blog.title}</h5>
                            <p>{blog.detail.replace(regex, "")}</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
          <div className="view-more">
            <Link to="/blogs" className="btn btn-outline-dark">
              View More >>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rides;
