import Layout from "../../../layout/layout";
import AddModelForm from "./addform";

const AddModel = () => {
  return (
    <Layout>
      <AddModelForm />
    </Layout>
  );
};

export default AddModel;
