import Bikeorder from "./dashboard/bikeorder";
import Counter from "./dashboard/counter";
import Partorder from "./dashboard/partorder";

function Dashboard() {
  return (
    <div className="page_wrapper">
      <div className="page-header">
        <h1>Dashboard </h1>
      </div>

      {/* <Counter />
      <div className="row mt-4">
        <div className="col-lg-6">
          <Bikeorder />
        </div>
        <div className="col-lg-6">
          <Partorder />
        </div>
      </div> */}
    </div>
  );
}

export default Dashboard;
