import { ofType } from "redux-observable";
import { catchError, switchMap, mergeMap, delay } from "rxjs/operators";
import { from, of } from "rxjs";
import { base_url } from "../constant";
import axios from "axios";

export const homeApi = async () => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "home", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      search: "hello",
    },
  });
  return res;
};

export const homeEpic = (action$) =>
  action$.pipe(
    ofType("HOME_SCREEN_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(homeApi()).pipe(
        mergeMap((response) => {
          //  console.log(response);
          if (action.cb) {
            action.cb(response?.data);
          }
          return of({
            type: "HOME_SCREEN_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "HOME_SCREEN_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchFrontContactsApi = async (formData) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "front/contact", formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const fetchFrontContactEpic = (action$) =>
  action$.pipe(
    ofType("FRONT_CONTACT_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchFrontContactsApi(action.payload.formData)).pipe(
        mergeMap((response) => {
          return of({
            type: "FRONT_CONTACT_DATA_SUCCESS",
            // payload: response?.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "FRONT_CONTACT_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const settingApi = async () => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "front/setting", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      search: "hello",
    },
  });
  return res;
};

export const settingEpic = (action$) =>
  action$.pipe(
    ofType("FRONT_SETTING_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(settingApi()).pipe(
        mergeMap((response) => {
          return of({
            type: "FRONT_SETTING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "HOME_SCREEN_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );
