import Layout from "../../../layout/layout";
import EditModelForm from "./editform";

const EditModel = () => {
  return (
    <Layout>
      <EditModelForm />
    </Layout>
  );
};

export default EditModel;
