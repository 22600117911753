import { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FrontLayout from "../layout/layout";
import Colors from "./colors";
import Description from "./descriptions";
import Features from "./features";
import Photos from "./photos";

import loading from "../../../assets/imgs/loading.gif";
import { Helmet } from "react-helmet";

const loadBikeDetail = (slug, cb) => {
  return {
    type: "BIKE_FRONT_DETAIL_DATA_ATTEMPT",
    payload: { slug: slug },
    cb,
  };
};

const BikeDetail = () => {
  const { slug } = useParams();
  const action = useDispatch();
  const store = useSelector((state) => state);
  const [bike, setBike] = useState({});
  const [bike_features, setBike_features] = useState([]);
  const [color, setColor] = useState([]);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    action(loadBikeDetail(slug, (detail) => {}));
  }, []);

  return (
    <FrontLayout>
      {!store.bike.isFetching ? (
        <>
          <Helmet>
            <title>{store.bike.detail.bike?.name}</title>
            <meta name="description" content={store.bike.detail.bike?.detail} />

            <meta property="og:url" content={window.location.href} />
            <meta property="og:title" content={store.bike.detail.bike?.name} />
            <meta
              property="og:description"
              content={store.bike.detail.bike?.detail}
            />
            <meta property="og:image" content={store.bike.detail.bike?.photo} />
          </Helmet>
          <Breadcrumb>
            <BreadcrumbItem>Home</BreadcrumbItem>
            <BreadcrumbItem>Motorcycle</BreadcrumbItem>
            <BreadcrumbItem>{store.bike.detail.bike?.name}</BreadcrumbItem>
          </Breadcrumb>

          {store.bike.detail.photos.length > 0 && (
            <Photos photos={store.bike.detail.photos} />
          )}
          {store.bike.detail.color.length > 0 && (
            <Colors colors={store.bike.detail.color} />
          )}
          {store.bike.detail.bike_features.length > 0 && (
            <Features features={store.bike.detail.bike_features} />
          )}
          {Object.values(store.bike.detail.bike).length > 0 && (
            <Description bike={store.bike.detail.bike} />
          )}
        </>
      ) : (
        <div className="view-more">
          <p>
            <img src={loading} />
          </p>
        </div>
      )}
    </FrontLayout>
  );
};

export default BikeDetail;
