let initialState = {
  response: {
    users: {
      email: "",
      name: "",
    },
  },
  isFetching: false,
  isError: false,
  isSuccess: false,
};
export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "PROFILE_FETCHING_DATA_SUCCESS":
      return {
        ...state,
        response: action.payload,
        isFetching: false,
        isError: false,
        isSuccess: false,
      };
    case "PROFILE_FETCHING_DATA_FAILURE":
      return {
        ...state,
        isError: action.payload,
        isFetching: false,
        isSuccess: false,
      };
    case "PROFILE_FETCHING_DATA_ATTEMPT":
      return {
        ...state,
        isFetching: true,
        isError: false,
        isSuccess: false,
      };
    case "RESET_ALL_LOADING":
      return {
        ...state,
        isFetching: false,
        isError: false,
        isSuccess: false,
      };
    case "LOGOUT_FETCHING_DATA_SUCCESS":
      return {
        response: [],
        isFetching: false,
        isError: false,
        isSuccess: false,
      };

    case "PROFILE_UPLOAD_DATA_FAILURE":
      return {
        ...state,
        isError: action.payload,
        isFetching: false,
      };

    case "PASSWORD_UPDATE_DATA_SUCCESS":
      return {
        ...state,
        isError: false,
        isFetching: false,
        isSuccess: action.payload,
      };

    case "PROFILE_UPDATE_DATA_SUCCESS":
      return {
        ...state,
        isError: false,
        isFetching: false,
        isSuccess: true,
      };

    case "PASSWORD_UPDATE_DATA_FAILURE":
      return {
        ...state,
        isError: action.payload,
        isFetching: false,
      };

    default:
      return state;
  }
}
