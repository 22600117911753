import React from "react";
import FrontLayout from "./layout/layout";
import img from "../../assets/imgs/royal-enfield-classic-350.jpg";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

const getDetail = (pageid, cb = false) => {
  return {
    type: "PAGE_DETAIL_DATA_ATTEMPT",
    payload: { pageid: pageid },
    cb,
  };
};

const Privacy = () => {
  const action = useDispatch();
  const store = useSelector((state) => state);
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");
  const [image, setImage] = useState("");
  const [pageVideo, setPageVideo] = useState("");
  useEffect(() => {
    action(
      getDetail(6, (detail) => {
        setName(detail.title);
        setDetail(detail.detail);
        setImage(detail.photo);
        setPageVideo(detail.video);
      })
    );
  }, []);
  return (
    <FrontLayout>
      <Breadcrumb>
        <BreadcrumbItem>Home</BreadcrumbItem>
        <BreadcrumbItem>{name}</BreadcrumbItem>
      </Breadcrumb>
      <div className="page-wrapper">
        <div className="page-container">
          {image ? (
            <img src={image} style={{ height: 300, width: "100%" }} />
          ) : (
            ""
          )}
          <h3>{name}</h3>
          <div dangerouslySetInnerHTML={{ __html: detail }}></div>
          <div className="page-video-wrapper">
            {pageVideo ? (
              <video width="70%" key={pageVideo} controls>
                <source src={pageVideo} type="video/mp4" />
              </video>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default Privacy;
