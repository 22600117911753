import React, { useEffect, useState } from "react";
import FrontLayout from "./layout/layout";

import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";

import loading from "../../assets/imgs/loading.gif";

const loadAllCustomers = (page, keyword = null) => {
  return {
    type: "FRONT_CUSTOMER_FETCHING_DATA_ATTEMPT",
    payload: { page: page, keyword: keyword },
  };
};

const CustomerList = () => {
  const action = useDispatch();
  const store = useSelector((state) => state);
  const [keyword, setKeyword] = useState(null);

  const [page, setPage] = useState(1);

  useEffect(() => {
    action(loadAllCustomers(page, keyword));
  }, [page]);

  const loadMorecustomers = () => {
    const bottom =
      window.innerHeight + window.scrollY >= document.body.clientHeight;
    // console.log("bottom", bottom);
    if (bottom && store.customer.response.has_next_page) {
      setPage(page + 1);
    }
  };

  const searchCustomer = (keyword) => {
    setPage(1);
    setKeyword(keyword);
    action(loadAllCustomers(page, keyword));
  };

  window.addEventListener("scroll", loadMorecustomers, true);

  return (
    <FrontLayout>
      <>
        <Breadcrumb>
          <BreadcrumbItem>Home</BreadcrumbItem>
          <BreadcrumbItem>Customers</BreadcrumbItem>
        </Breadcrumb>
        <div className="section-title customer-title">
          <div>
            <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon>
            <span>Happiest Customers</span>
          </div>
          <input
            type="text"
            id="myInput"
            placeholder="Search for Customers.."
            title="Type in a name"
            onKeyDown={(e) => searchCustomer(e.target.value)}
          />
        </div>
        <div className="wrapper-ride">
          <div className="container-ride">
            <div className="wrapper-bikelists">
              {store.customer.response.customers.length > 0
                ? store.customer.response.customers.map((customer, index) => {
                    return (
                      <div className="ride-wrapper" key={index}>
                        <div className="ride">
                          <a href="">
                            {" "}
                            <img
                              src={customer.thumbnail}
                              className="blog-img"
                            />
                            <div className="content">
                              <h5>{customer.title}</h5>
                              <p>
                                {" "}
                                {customer.bike} - {customer.color}
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
            {store.customer.isFetching ? (
              <div className="view-more">
                <p>
                  <img src={loading} />
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    </FrontLayout>
  );
};

export default CustomerList;
