import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { confirm } from "react-confirm-box";
import Layout from "../../../layout/layout";

const loadBikePhotos = (page, bikeid) => {
  return {
    type: "BIKE_PHOTO_FETCHING_DATA_ATTEMPT",
    payload: { page, bikeid },
  };
};

const deleteBikePhotoRecord = (bikePhotoId) => {
  // alert(bikeColorId);
  return { type: "BIKE_PHOTO_DELETE_DATA_ATTEMPT", payload: { bikePhotoId } };
};

const getBikes = () => {
  return { type: "BIKE_PRESET_FETCH_DATA_ATTEMPT" };
};

const BikePhotos = () => {
  const action = useDispatch();
  const store = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [bike, setBike] = useState("");

  useEffect(() => {
    action(loadBikePhotos(page, bike));
  }, [page]);

  useEffect(() => {
    action(getBikes());
  }, []);

  const loadMoreBikePhotos = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && store.model.response.has_next_page) {
      setPage(page + 1);
    }
  };

  const deleteBikePhoto = async (bike_color_id) => {
    const result = await confirm("Are you sure to delete this Bike?");
    if (result) {
      action(deleteBikePhotoRecord(bike_color_id));
    }
  };

  const searchPhotos = (e) => {
    const bike = e.target.value;
    setBike(bike);
    setPage(1);
    action(loadBikePhotos(page, bike));
  };
  return (
    <Layout>
      <div className="page_wrapper">
        <div className="page-header">
          <h1>
            Bike Photos{" "}
            <Link
              className="btn btn-primary float-right"
              to="/admin/bike/add-photo"
            >
              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add
            </Link>
          </h1>
          <div className="form-group row mb-30">
            <label htmlFor="bike_model" className="col-md-3">
              Bike
            </label>
            <div className="col-md-6">
              {store.bike.bikePresets.length > 0 ? (
                <select
                  value={bike}
                  className="form-control"
                  onChange={(e) => searchPhotos(e)}
                >
                  <option value="">Select</option>

                  {store.bike.bikePresets.map((bike, key) => {
                    return (
                      <option key={key} value={bike.id}>
                        {bike.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                "No Bikes"
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-sd-12 col-md-12  body-list"
            onScroll={(event) => {
              loadMoreBikePhotos(event);
            }}
          >
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Bike Name</th>
                  <th scope="col">Title</th>
                  <th scope="col">Image</th>
                  <th>Status</th>
                  <th scope="col"> Action </th>
                </tr>
              </thead>
              <tbody>
                {store.bike.bikePhotos.bike_photos.length > 0 ? (
                  store.bike.bikePhotos.bike_photos.map((bike, key) => {
                    return (
                      <tr key={bike.id}>
                        <td scope="col">#{key + 1}</td>
                        <td scope="col">{bike.bike_name}</td>
                        <td scope="col">{bike.title}</td>
                        <td scope="col">
                          <img src={bike.bike_thumbnail} className="img-100" />
                        </td>
                        <td>
                          <span className={bike.status}>{bike.status}</span>
                        </td>
                        <td scope="col">
                          <div className="action">
                            <Link to={`/admin/bike/${bike.id}/photo`}>
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                              ></FontAwesomeIcon>
                            </Link>
                            <FontAwesomeIcon
                              className="link_pointer"
                              icon={faTrashCan}
                              onClick={() => deleteBikePhoto(bike.id)}
                            ></FontAwesomeIcon>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td scope="row" colSpan={7}>
                      No Bikes found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BikePhotos;
