import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Features = (props) => {
  const features = props.features;

  return (
    <div className="bike-features">
      <Carousel responsive={responsive}>
        {features.map((feature, index) => {
          return (
            <div>
              {" "}
              <h5>{feature.title}</h5>
              <p>{feature.detail}</p>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
export default Features;
