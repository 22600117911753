import Layout from "../../../layout/layout";
import EditPageForm from "./editPageForm";

const EditPage = () => {
  return (
    <Layout>
      <EditPageForm />
    </Layout>
  );
};

export default EditPage;
