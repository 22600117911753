import { ofType } from "redux-observable";
import { catchError, switchMap, mergeMap, delay } from "rxjs/operators";
import { from, of } from "rxjs";
import { base_url } from "../constant";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export const fetchBlogsApi = async (action) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "blogs", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      page: action.payload.page,
      keyword: action.payload.keyword,
    },
  });
  return res;
};

export const fetchBlogEpic = (action$) =>
  action$.pipe(
    ofType("BLOG_FETCHING_DATA_ATTEMPT", "BLOG_DELETE_DATA_SUCCESS"),
    mergeMap((action) =>
      from(fetchBlogsApi(action)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "BLOG_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "BLOG_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const addBlogsApi = async (formData) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "blog/add", formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const addBlogEpic = (action$) =>
  action$.pipe(
    ofType("BLOG_ADD_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(addBlogsApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "BLOG_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BLOG_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchDetailBlogApi = async (payload) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "blog/" + payload.blogid, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      // modelid: payload.modelid,
    },
  });
  return res;
};

export const fetchDetailBlogEpic = (action$) =>
  action$.pipe(
    ofType("BLOG_DETAIL_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchDetailBlogApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          if (action.cb) {
            action.cb(response?.data);
          }
          return of({
            type: "BLOG_DETAIL_DATA_SUCCESS",
            payload: response?.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BLOG_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const editBlogApi = async (formData, blogid) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(base_url + "blog/edit/" + blogid, formData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const editBlogEpic = (action$) =>
  action$.pipe(
    ofType("BLOG_EDIT_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(editBlogApi(action.payload, action.blogid)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "BLOG_ADD_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BLOG_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const deleteBlogApi = async (blogid) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.post(
    base_url + "blog/delete/" + blogid,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res;
};

export const deleteBlogEpic = (action$) =>
  action$.pipe(
    ofType("BLOG_DELETE_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(deleteBlogApi(action.payload.blogid)).pipe(
        mergeMap((response) => {
          toast("Success!");
          console.log("response", response);
          return of({
            type: "BLOG_DELETE_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BLOG_ADD_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchFrontBlogsApi = async (action) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "front/blogs", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      page: action.payload.page,
      keyword: action.payload.keyword,
    },
  });
  return res;
};

export const fetchFrontBlogEpic = (action$) =>
  action$.pipe(
    ofType("FRONT_BLOG_FETCHING_DATA_ATTEMPT"),
    mergeMap((action) =>
      from(fetchFrontBlogsApi(action)).pipe(
        mergeMap((response) => {
          console.log(response);
          return of({
            type: "BLOG_FETCHING_DATA_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.log(err);
          return of({
            type: "BLOG_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

export const fetchDetailBlogSlugApi = async (payload) => {
  const token = await localStorage.getItem("ramro_token");
  const res = await axios.get(base_url + "blog/detail/" + payload.slug, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      // modelid: payload.modelid,
    },
  });
  return res;
};

export const fetchDetailBlogSlugEpic = (action$) =>
  action$.pipe(
    ofType("BLOG_DETAIL_DATA_SLUG_ATTEMPT"),
    mergeMap((action) =>
      from(fetchDetailBlogSlugApi(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          if (action.cb) {
            action.cb(response?.data);
          }
          return of({
            type: "BLOG_DETAIL_DATA_SUCCESS",
            payload: response?.data,
          });
        }),
        catchError((err) => {
          console.log("error", err);
          return of({
            type: "BLOG_FETCHING_DATA_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );
