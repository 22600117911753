import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import loadingImage from "../../../assets/imgs/loading.gif";
import TrRow from "./trRow";

const loadBlogs = (page, keyword) => {
  return { type: "BLOG_FETCHING_DATA_ATTEMPT", payload: { page, keyword } };
};

const List = () => {
  const action = useDispatch();
  const store = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    action(loadBlogs(page, keyword));
    setLoading(false);
  }, [page]);

  const loadMoreBlogs = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && store.page.response.has_next_page) {
      setPage(page + 1);
    }
  };
  const searchByKeyword = (keyword) => {
    setPage(1);
    setKeyword(keyword);
    action(loadBlogs(page, keyword));
  };
  return (
    <div className="page_wrapper">
      <div className="page-header">
        <h1>
          Blog{" "}
          <Link to="/admin/blog/add" className="btn btn-primary float-right">
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add
          </Link>
        </h1>
        <div className="search-wrapper">
          <div className="customer-title-admin">
            <input
              type="text"
              id="myInput"
              placeholder="Search for Keyword.."
              title="Type in a name"
              onKeyDown={(e) => searchByKeyword(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className="col-sd-12 col-md-12  body-list"
          onScroll={(event) => {
            loadMoreBlogs(event);
          }}
        >
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Blog Title</th>
                <th scope="col">Image</th>
                <th scope="col">Status</th>
                <th scope="col"> Action </th>
              </tr>
            </thead>
            {loading ? (
              <img src={loadingImage} />
            ) : (
              <tbody>
                {store.blog.response.blogs.length > 0 ? (
                  store.blog.response.blogs.map((blog) => (
                    <TrRow key={blog.id} blog={blog} />
                  ))
                ) : (
                  <tr>
                    <td scope="row" colSpan={5} style={{ textAlign: "center" }}>
                      No Blog found
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default List;
