import { faAd, faCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast, Icons } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { base_url } from "../../../constant";
import Layout from "../../../layout/layout";

const submitBikeForm = (formData, id) => {
  return { type: "BIKE_EDIT_DATA_ATTEMPT", payload: formData, bikeid: id };
};

const getBikeDetail = (bikeid, cb = false) => {
  //   alert(bikeid);
  return {
    type: "BIKE_DETAIL_DATA_ATTEMPT",
    payload: { bikeid: bikeid },
    cb,
  };
};

const getBikeModels = () => {
  return { type: "BIKE_MODEL_FETCH_DATA_ATTEMPT" };
};

const EditBike = () => {
  const { id } = useParams();

  const action = useDispatch();
  const navigate = useNavigate();
  const [model, setModel] = useState("");
  const [bikeTitle, setBikeTitle] = useState("");
  const [detail, setDetail] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [status, setStatus] = useState("Active");
  const [pdfFile, setPdfFile] = useState("");
  const [pdf, setPdf] = useState("");
  const [pdfError, setPdfError] = useState("");

  const [modelError, setModelError] = useState("");
  const [imageError, setImageError] = useState("");
  const [bikeTitleError, setBikeTitleError] = useState("");

  const [data, setData] = useState([]);

  const store = useSelector((state) => state);

  useEffect(() => {
    action(
      getBikeDetail(id, (detail) => {
        setBikeTitle(detail.name);
        setDetail(detail.detail);
        setImage(detail.thumbnail);
        setStatus(detail.status);
        setPdf(detail.manual);
        const features = detail.features.map((feature, i) => {
          return [
            {
              label: "Feature Name",
              field: "featureName",
              value: feature.title,
              name: `${data.length}0`,
            },
            {
              label: "Value",
              field: "value",
              value: feature.detail,
              name: `${data.length}1`,
            },
          ];
        });
        setData(features);
      })
    );
  }, []);

  useEffect(() => {
    if (store.bike.isSuccess) {
      toast("Success!");
      navigate("/admin/bikes");
    }
  }, [store.bike.isSuccess]);

  const onChangeImg = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageFile(event.target.files[0]);
  };

  const onChangePdf = (event) => {
    setPdf(URL.createObjectURL(event.target.files[0]));
    setPdfFile(event.target.files[0]);
  };

  const handleBikeForm = (event) => {
    event.preventDefault();
    let error = false;

    if (bikeTitle == "") {
      setBikeTitleError("Bike name is required.");
      error = true;
    }

    if (image == "") {
      setImageError("Image is required.");
      error = true;
    }

    if (error == true) return false;

    const formData = new FormData();
    formData.append("files", imageFile);
    formData.append("pdf", pdfFile);
    formData.append("title", bikeTitle);
    formData.append("detail", detail);
    formData.append("status", status);

    const features = [];
    const feature_values = [];
    data.map((d, i1) => {
      formData.append("features[]", d[0].value);
      formData.append("feature_values[]", d[1].value);
    });

    action(submitBikeForm(formData, id));
  };

  const handleOnChange = (e, row, col) => {
    const newData = data.map((d, i) => {
      if (i === row) {
        d[col].value = e.target.value;
      }

      return d;
    });
    setData(newData);
  };

  const addRow = () => {
    console.log(data);
    setData([
      ...data,
      [
        {
          label: "Property Name",
          field: "propertyName",
          value: "",
          name: `${data.length}0`,
        },
        {
          label: "Value",
          field: "value",
          value: "",
          name: `${data.length}1`,
        },
      ],
    ]);
  };

  const removeRow = (index) => {
    const _data = [...data];
    _data.splice(index, 1);
    setData(_data);
  };

  return (
    <Layout>
      <div className="page_wrapper">
        <div className="page-header">
          <h1>Edit Bike</h1>
        </div>

        <form onSubmit={(e) => handleBikeForm(e)}>
          <div className="form-group row mb-30">
            <label htmlFor="bike_model" className="col-md-3">
              Bike Name
            </label>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                id="bike_name"
                aria-describedby="emailHelp"
                value={bikeTitle}
                placeholder="Bike name"
                onChange={(e) => setBikeTitle(e.target.value)}
              />
              <p className="error"> {bikeTitleError}</p>
            </div>
          </div>
          <div className="form-group row mb-30">
            <label htmlFor="detail" className="col-md-3">
              Detail
            </label>
            <div className="col-md-6">
              <CKEditor
                editor={ClassicEditor}
                data={detail}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                config={{
                  ckfinder: {
                    // Upload the images to the server using the CKFinder QuickUpload command.
                    uploadUrl: base_url + "uplaodImageFromEditor",
                  },
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDetail(data);
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </div>
          </div>
          <div className="form-group row mb-30">
            <label htmlFor="image" className="col-md-3">
              Image
            </label>
            <div className="col-md-6">
              <input
                type="file"
                className="form-control-file"
                id="image"
                name="image"
                onChange={(e) => onChangeImg(e)}
                accept="image/*"
              />
              <p className="error">{imageError}</p>
            </div>
            <div className="col-md-3">
              <img src={image} className="field_image" />
            </div>
          </div>
          <div className="form-group row mb-30">
            <label htmlFor="image" className="col-md-3">
              Specification (PDF only)
            </label>
            <div className="col-md-6">
              <input
                type="file"
                className="form-control-file"
                id="pdf"
                name="pdf"
                onChange={(e) => onChangePdf(e)}
                accept="application/pdf"
              />
              <p className="error">{pdfError}</p>
            </div>
            <div className="col-md-3">
              {pdf != "" ? (
                <a href={pdf} target="_blank">
                  Specification
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form-group row mb-30">
            <label htmlFor="status" className="col-md-3">
              Status
            </label>
            <div className="col-md-6">
              <select
                value={status}
                className="form-control"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
            <div className="col-md-3"></div>
          </div>

          <div className="form-group row mb-30">
            <label htmlFor="status" className="col-md-3">
              <strong>Bike Features</strong>
            </label>
            <div className="col-md-6">
              <FontAwesomeIcon icon={faCirclePlus} onClick={addRow} />
            </div>
            <div className="col-md-3"></div>
          </div>

          {data.map((items, i1) => (
            <div key={i1} className="content">
              <div className="form-group row mb-30">
                <label htmlFor="status" className="col-md-3">
                  Bike Features {i1 + 1}
                </label>
                {items.map((item, i2) => (
                  <div className="col-md-3" key={i2}>
                    <input
                      className="form-control"
                      type="text"
                      key={i2}
                      placeholder={item.label}
                      value={item.value}
                      onChange={(e) => handleOnChange(e, i1, i2)}
                      variant="outlined"
                      name={item.name}
                    />
                  </div>
                ))}
                <label htmlFor="status" className="col-md-3">
                  {" "}
                  {data.length > 1 && (
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => removeRow(i1)}
                    />
                  )}
                </label>
              </div>
            </div>
          ))}

          <div className="form-group row mb-30">
            <label className="col-md-3"></label>
            <div className="col-md-6">
              <button type="submit" className="btn btn-primary mr-30">
                Submit
              </button>
              <Link to="/admin/bikes" className="btn btn-danger">
                Back
              </Link>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditBike;
