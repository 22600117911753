import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const HomeBikes = (props) => {
  const [bikes, setBikes] = useState(props.bikes);

  useEffect(() => {
    setBikes(props.bikes);
  }, [props.bikes]);
  // console.log("Bikes", bikes);
  return (
    <>
      <div className="section-title">Motercycles</div>
      <div className="wrapper-bikelists">
        {bikes.length > 0
          ? bikes.map((bike, key) => {
              return (
                <div className="bike" key={key}>
                  <Link to={`/motorcycles/${bike.slug}`}>
                    {" "}
                    <img src={bike.thumbnail} />
                    <div className="explore">{bike.detail}</div>
                    <div className="bikeTitle">{bike.name}</div>
                  </Link>
                </div>
              );
            })
          : "No Bike Added Yet."}
      </div>
    </>
  );
};

export default HomeBikes;
