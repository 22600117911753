import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import logger from "redux-logger";
import reportWebVitals from "./reportWebVitals";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "./reducers/epic";
// import * as serviceWorker from './serviceWorker';
import allReducers from "./reducers";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
const epicmiddleware = createEpicMiddleware();

const store = createStore(
  allReducers,
  applyMiddleware(...[epicmiddleware, logger])
);
epicmiddleware.run(rootEpic);

const root = ReactDOM.createRoot(document.getElementById("root"));

console.log("from index.js");
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
