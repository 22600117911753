let initialState = {
  response: {
    bikes: [],
    has_next_page: true,
  },
  bikeModels: [],
  bikePresets: [],
  bikeColors: {
    bike_colors: [],
    has_next_page: true,
  },
  bikePhotos: {
    bike_photos: [],
    has_next_page: true,
  },
  isFetching: false,
  isError: false,
  isSuccess: false,
  bikeColorDetail: {},
  bikePhotoDetail: {},
  detail: { bike: {}, bike_features: [], color: [], photos: [] },
  bikeOrders: {
    orders: [],
    has_next_page: true,
  },
};

export default function bikeReducer(state = initialState, action) {
  switch (action.type) {
    case "BIKE_PHOTO_DETAIL_DATA_SUCCESS":
      return {
        ...state,
        isError: false,
        isFetching: false,
        isSuccess: false,
        bikePhotoDetail: action.payload,
      };
    case "BIKE_PHOTO_FETCHING_DATA_SUCCESS":
      return {
        ...state,
        bikePhotos: {
          bike_photos:
            action.payload.current_page > 1
              ? [...state.bikePhotos.bike_photos, ...action.payload.bike_photos]
              : [...action.payload.bike_photos],
          has_next_page: action.payload.has_next_page,
        },
        isFetching: false,
        isError: false,
        isSuccess: false,
      };
    case "ORDER_FETCHING_DATA_SUCCESS":
      // console.log("models", action.payload);
      return {
        ...state,
        bikeOrders: {
          orders:
            action.payload.current_page > 1
              ? [...state.bikeOrders.orders, ...action.payload.orders]
              : [...action.payload.orders],
          has_next_page: action.payload.has_next_page,
        },
        isFetching: false,
        isError: false,
        isSuccess: false,
      };
    case "BIKE_PHOTO_ADD_DATA_SUCCESS":
      return {
        ...state,
        isError: false,
        isFetching: false,
        isSuccess: true,
      };
    case "BIKE_COLOR_DETAIL_DATA_SUCCESS":
      return {
        ...state,
        isError: false,
        isFetching: false,
        isSuccess: false,
        bikeColorDetail: action.payload,
      };

    case "BIKE_PRESET_FETCHING_DATA_SUCCESS":
      return {
        ...state,
        bikePresets: action.payload,
        isFetching: false,
        isError: false,
        isSuccess: false,
      };

    case "BIKE_COLOR_ADD_DATA_SUCCESS":
      return {
        ...state,
        isError: false,
        isFetching: false,
        isSuccess: true,
      };
    case "BIKE_MODEL_FETCHING_DATA_SUCCESS":
      return {
        ...state,
        bikeModels: action.payload,
        isFetching: false,
        isError: false,
        isSuccess: false,
      };
    case "BIKE_COLOR_FETCHING_DATA_SUCCESS":
      // console.log("BIKE_COLOR_FETCHING_DATA_SUCCESS", action.payload);
      return {
        ...state,
        bikeColors: {
          bike_colors:
            action.payload.current_page > 1
              ? [...state.bikeColors.bike_colors, ...action.payload.bike_colors]
              : [...action.payload.bike_colors],
          has_next_page: action.payload.has_next_page,
        },
        isFetching: false,
        isError: false,
        isSuccess: false,
      };
    case "BIKE_FETCHING_DATA_SUCCESS":
      // console.log("models", action.payload);
      return {
        ...state,
        response: {
          bikes:
            action.payload.current_page > 1
              ? [...state.response.bikes, ...action.payload.bikes]
              : [...action.payload.bikes],
          has_next_page: action.payload.has_next_page,
        },
        isFetching: false,
        isError: false,
        isSuccess: false,
      };
    case "BIKE_FETCHING_DATA_FAILURE":
      return {
        ...state,
        isError: action.payload,
        isFetching: false,
        isSuccess: false,
      };

    case "BIKE_ADD_DATA_SUCCESS":
      return {
        ...state,
        isError: false,
        isFetching: false,
        isSuccess: true,
      };

    case "HOME_SCREEN_DATA_SUCCESS":
      return {
        ...state,
        isError: false,
        isFetching: false,
        isSuccess: false,
      };

    case "FRONT_BIKE_FETCH_ATTEMPT":
      return {
        ...state,
        isError: false,
        isFetching: true,
        isSuccess: true,
      };

    case "HOME_SCREEN_DATA_ATTEMPT":
      return {
        ...state,
        isError: false,
        isFetching: true,
        isSuccess: true,
      };

    case "BIKE_FRONT_BOOK_DATA_ATTEMPT":
      return {
        ...state,
        isError: false,
        isFetching: true,
        isSuccess: true,
      };

    case "BIKE_DETAIL_DATA_SUCCESS":
      return {
        ...state,
        isError: false,
        isFetching: false,
        isSuccess: false,
        detail: action.payload,
      };

    case "BIKE_RESET_ALL_LOADING":
      return {
        ...state,
        isFetching: false,
        isError: false,
        isSuccess: false,
      };
    default:
      return state;
  }
}
