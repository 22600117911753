import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TrModel from "./trmodel";
import loadingImage from "../../../assets/imgs/loading.gif";

const loadModels = (page) => {
  return { type: "MODEL_FETCHING_DATA_ATTEMPT", payload: { page } };
};

const List = () => {
  const action = useDispatch();
  const store = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    action(loadModels(page));
    setLoading(true);
  }, [page]);

  const loadMoreModel = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && store.model.response.has_next_page) {
      setPage(page + 1);
    }
  };
  return (
    <div className="page_wrapper">
      <div className="page-header">
        <h1>
          Slider{" "}
          <Link to="/admin/model/add" className="btn btn-primary float-right">
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add
          </Link>
        </h1>
      </div>

      <div className="row">
        <div
          className="col-sd-12 col-md-12  body-list"
          onScroll={(event) => {
            loadMoreModel(event);
          }}
        >
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Title</th>
                <th scope="col">Image</th>
                <th scope="col">Status</th>
                <th scope="col"> Action </th>
              </tr>
            </thead>
            <tbody>
              {store.model.response.models.length > 0 ? (
                store.model.response.models.map((model) => (
                  <TrModel key={model.id} model={model} />
                ))
              ) : (
                <tr>
                  <td scope="row" colSpan={5} style={{ textAlign: "center" }}>
                    {loading ? <img src={loadingImage} /> : "No Models found"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default List;
