import { combineEpics } from "redux-observable";
import {
  loginEpic,
  uploadProfileImgEpic,
  updateProfileEpic,
  updatePasswordEpic,
} from "../epics/profile";

import {
  addModelEpic,
  fetchModelEpic,
  fetchDetailModelEpic,
  editModelEpic,
  deleteModelEpic,
} from "../epics/modelEpic";

import {
  fetchBikeModelEpic,
  addBikeEpic,
  fetchBikeEpic,
  fetchBikeDetailEpic,
  editBikeEpic,
  deleteBikeEpic,
  fetchBikeColorEpic,
  addBikeColorEpic,
  fetchColorBikesEpic,
  fetchBikeColorDetailEpic,
  editBikeColorEpic,
  deleteBikeColorEpic,
  addBikePhotoEpic,
  fetchBikePhotoEpic,
  fetchBikePhotoDetailEpic,
  editBikePhotoEpic,
  deleteBikePhotoEpic,
  fetchFrontBikeDetailEpic,
  fetchFrontBikeBookEpic,
  fetchFrontBikeListEpic,
  fetchOrderEpic,
  fetchContactEpic,
} from "../epics/bikeEpic";

import {
  addPageEpic,
  fetchPageEpic,
  fetchDetailPageEpic,
  editPageEpic,
  deletePageEpic,
} from "../epics/pageEpic";

import {
  addBlogEpic,
  fetchBlogEpic,
  fetchDetailBlogEpic,
  editBlogEpic,
  deleteBlogEpic,
  fetchFrontBlogEpic,
  fetchDetailBlogSlugEpic,
} from "../epics/blogEpic";

import {
  addCustomerEpic,
  fetchCustomerEpic,
  fetchDetailCustomerEpic,
  editCustomerEpic,
  deleteCustomerEpic,
  fetchFrontCustomerEpic,
} from "../epics/customerEpic";

import {
  homeEpic,
  fetchFrontContactEpic,
  settingEpic,
} from "../epics/frontEpic";

export const rootEpic = combineEpics(
  loginEpic,
  uploadProfileImgEpic,
  updateProfileEpic,
  updatePasswordEpic,
  addModelEpic,
  fetchModelEpic,
  fetchDetailModelEpic,
  editModelEpic,
  deleteModelEpic,
  fetchBikeModelEpic,
  addBikeEpic,
  fetchBikeEpic,
  fetchBikeDetailEpic,
  editBikeEpic,
  deleteBikeEpic,
  fetchBikeColorEpic,
  addBikeColorEpic,
  fetchColorBikesEpic,
  fetchBikeColorDetailEpic,
  editBikeColorEpic,
  deleteBikeColorEpic,
  addBikePhotoEpic,
  fetchBikePhotoEpic,
  fetchBikePhotoDetailEpic,
  editBikePhotoEpic,
  fetchOrderEpic,
  deleteBikePhotoEpic,
  addPageEpic,
  fetchPageEpic,
  fetchDetailPageEpic,
  editPageEpic,
  deletePageEpic,
  addBlogEpic,
  fetchBlogEpic,
  fetchDetailBlogEpic,
  editBlogEpic,
  deleteBlogEpic,
  addCustomerEpic,
  fetchCustomerEpic,
  fetchDetailCustomerEpic,
  editCustomerEpic,
  deleteCustomerEpic,
  homeEpic,
  fetchFrontCustomerEpic,
  fetchFrontBlogEpic,
  fetchFrontBikeDetailEpic,
  fetchFrontBikeBookEpic,
  fetchFrontBikeListEpic,
  fetchFrontContactEpic,
  settingEpic,
  fetchDetailBlogSlugEpic,
  fetchContactEpic
);
