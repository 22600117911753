import React from "react";
import { useState } from "react";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import FrontLayout from "./layout/layout";

const submitForm = (formData) => {
  return { type: "FRONT_CONTACT_DATA_ATTEMPT", payload: { formData } };
};

const Contact = () => {
  const store = useSelector((state) => state);
  const action = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [messageError, setMessageError] = useState("");

  const navigate = useNavigate();

  if (store.front.isSuccess) {
    toast(
      "Success! Your request has been sent. We will contact you within 24 Hours."
    );
    // setName("");
  }

  const handleForm = (event) => {
    event.preventDefault();
    let error = false;

    if (name == "") {
      setNameError("required");
      error = true;
    }

    if (email == "") {
      setEmailError("required");
      error = true;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailError("required");
      error = true;
    }

    if (mobile == "") {
      setMobileError("required");
      error = true;
    }

    if (address == "") {
      setAddressError("required");
      error = true;
    }

    if (message == "") {
      setMessageError("required");
      error = true;
    }

    if (error) {
      return false;
    } else {
      const formData = new FormData();
      // formData.append("files", imageFile);
      formData.append("message", message);
      formData.append("name", name);
      formData.append("mobile", mobile);
      formData.append("email", email);
      formData.append("address", address);
      action(submitForm(formData));
    }
  };
  return (
    <FrontLayout>
      <Breadcrumb>
        <BreadcrumbItem>Home</BreadcrumbItem>
        <BreadcrumbItem>Contact Us</BreadcrumbItem>
      </Breadcrumb>
      <div className="page-wrapper">
        <div className="page-container">
          <div className="page-header">
            <h3>Get In Touch With Us</h3>
            <h6>Feel Free To Say Hello</h6>
          </div>
          <div className="contact-body">
            <div className="contact-detail">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.457006650149!2d85.40424791453752!3d27.6722667336407!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1b69cf437acf%3A0x64ab9d322b20e14d!2sVintage%20Moto%20Cafe%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1663600913329!5m2!1sen!2snp"
                width="100%"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="contact-form">
              <h5>Please fill in your details</h5>
              <form
                onSubmit={(e) => {
                  handleForm(e);
                }}
              >
                <div className="ride-form-wrapper">
                  <div className="ride-form-left">
                    <label htmlFor="name">
                      <b>Name</b>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                      onChange={(e) => {
                        setName(e.target.value);
                        setNameError("");
                      }}
                      className={nameError}
                    />

                    <label htmlFor="email">
                      <b>Email</b>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Email"
                      name="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError("");
                      }}
                      className={emailError}
                    />
                  </div>
                  <div className="ride-form-right">
                    <label htmlFor="mobile">
                      <b>Mobile Number</b>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Mobile Number"
                      name="mobile"
                      onChange={(e) => {
                        setMobile(e.target.value);
                        setMobileError("");
                      }}
                      className={mobileError}
                    />

                    <label htmlFor="address">
                      <b>Address</b>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Address"
                      name="address"
                      onChange={(e) => {
                        setAddress(e.target.value);
                        setAddressError("");
                      }}
                      className={addressError}
                    />
                  </div>
                </div>

                <div className="form-wrapper">
                  <label htmlFor="address">
                    <b>Your Query</b>
                  </label>
                  <textarea
                    placeholder="Brief description of your query"
                    name="message"
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setMessageError("");
                    }}
                    className={messageError}
                  />
                  <button type="submit" className="btn-ride">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default Contact;
