import { faBars, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import logo from "../../../assets/imgs/logo.svg";

const Header = (prop) => {
  return (
    <div className="wrapper-header">
      <div className="header-left">
        <Link to={`/`}>
          {" "}
          <img src={prop.setting.logo_photo} className="logo" />
        </Link>
      </div>
      <div className="header-nav">
        <div className="nav-menu">
          <ul>
            <li>
              <Link to={`/motorcycles`}>Motercycles</Link>
            </li>
            <li>
              <Link to={`/about-us`}>About Us</Link>
            </li>
            <li>
              <Link to={`/blogs`}>Blogs</Link>
            </li>
            <li>
              <Link to={`/contact-us`}>Contact us</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="header-right">
        <div className="header-contact">
          <ul>
            <li>
              <a href={`mailto: ${prop.setting.email}`}>
                <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> &nbsp;
                {prop.setting.email}
              </a>
            </li>
            <li>
              <a href={`tel: ${prop.setting.contact_no1}`}>
                <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>&nbsp;
                {prop.setting.contact_no1}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="topnav" id="myTopnav">
        <div className="dropdown">
          <a>
            {" "}
            <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
          </a>
          <div className="dropdown-content">
            <Link to={`/motorcycles`}>Motercycles</Link>
            <Link to={`/about-us`}>About Us</Link>
            <Link to={`/blogs`}>Blogs</Link>
            <Link to={`/contact-us`}>Contact us</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
