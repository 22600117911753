import Layout from "../../layout/layout";
import List from "./bikes/list";
const Bikes = () => {
  return (
    <Layout>
      <List />
    </Layout>
  );
};

export default Bikes;
