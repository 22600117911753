import Layout from "../../../layout/layout";
import AddPageForm from "./addPageForm";

const AddPage = () => {
  return (
    <Layout>
      <AddPageForm />
    </Layout>
  );
};

export default AddPage;
