import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const submitModel = (formData, id) => {
  return { type: "MODEL_EDIT_DATA_ATTEMPT", payload: formData, modelid: id };
};
const getModelDetail = (modelid, cb = false) => {
  return {
    type: "MODEL_DETAIL_DATA_ATTEMPT",
    payload: { modelid: modelid },
    cb,
  };
};
const EditModelForm = () => {
  const { id } = useParams();
  //   alert(id);
  const store = useSelector((state) => state);

  const action = useDispatch();
  const navigate = useNavigate();
  const [model, setModel] = useState("");
  const [detail, setDetail] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState();
  const [status, setStatus] = useState("");

  const [modelError, setModelError] = useState("");

  useEffect(() => {
    action(
      getModelDetail(id, (detail) => {
        setModel(detail.title);
        setDetail(detail.detail);
        setImage(detail.thumbnail);
        setStatus(detail.status);
      })
    );
  }, []);

  useEffect(() => {
    if (store.model.isSuccess) {
      toast("Success!");
      navigate("/admin/models");
    }
  }, [store.model.isSuccess]);

  const onChangeImg = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageFile(event.target.files[0]);
  };

  const handleModelForm = (event) => {
    event.preventDefault();
    let error = false;
    if (model == "") {
      setModelError("Bike model is required.");
      error = true;
    }

    if (error == true) return false;
    const formData = new FormData();
    formData.append("files", imageFile);
    formData.append("model", model);
    formData.append("detail", detail);
    formData.append("status", status);
    action(submitModel(formData, id));
  };

  return (
    <div className="page_wrapper">
      <div className="page-header">
        <h1>Edit Model</h1>
      </div>

      <form onSubmit={(e) => handleModelForm(e)}>
        <div className="form-group row mb-30">
          <label htmlFor="bike_model" className="col-md-3">
            Bike Model
          </label>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              id="bike_model"
              aria-describedby="emailHelp"
              value={model}
              placeholder="Bike model"
              onChange={(e) => setModel(e.target.value)}
            />
            <p className="error">{modelError}</p>
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="detail" className="col-md-3">
            Detail
          </label>
          <div className="col-md-6">
            <textarea
              type="text"
              className="form-control"
              id="detail"
              placeholder="Detail"
              defaultValue={detail}
              onChange={(e) => setDetail(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="image" className="col-md-3">
            Image
          </label>
          <div className="col-md-6">
            <input
              type="file"
              className="form-control-file"
              id="image"
              name="image"
              onChange={(e) => onChangeImg(e)}
              accept="image/*"
            />
          </div>
          <div className="col-md-3">
            <img src={image} className="field_image" />
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="status" className="col-md-3">
            Status
          </label>
          <div className="col-md-6">
            <select
              value={status}
              className="form-control"
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="form-group row mb-30">
          <label className="col-md-3"></label>
          <div className="col-md-6">
            <button type="submit" className="btn btn-primary mr-30">
              Submit
            </button>
            <Link to="/models" className="btn btn-danger">
              Back
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditModelForm;
