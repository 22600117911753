let initialState = {
  isFetching: false,
  isError: false,
  isSuccess: false,
  setting: {},
};
const frontReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FRONT_CONTACT_DATA_ATTEMPT":
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
      };

    case "FRONT_CONTACT_DATA_SUCCESS":
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
      };

    case "FRONT_SETTING_DATA_SUCCESS":
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        setting: action.payload,
      };

    default:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
      };
  }
};

export default frontReducer;
