let initialState = {
  response: {
    contacts: [],
    has_next_page: true,
  },
  isFetching: false,
  isError: false,
  isSuccess: false,
};
export default function contactReducer(state = initialState, action) {
  switch (action.type) {
    case "CONTACT_FETCHING_DATA_SUCCESS":
      return {
        ...state,
        response: {
          contacts:
            action.payload.current_page > 1
              ? [...state.response.contacts, ...action.payload.contacts]
              : [...action.payload.contacts],
          has_next_page: action.payload.has_next_page,
        },
        isFetching: false,
        isError: false,
        isSuccess: false,
      };
    default:
      return state;
  }
}
