import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import img from "../../assets/imgs/interceptor-homepage.jpg";

const PageVideo = (props) => {
  // console.log("Videos", props);
  const [photo, setPhoto] = useState(props.home.photo);
  const [video, setVideo] = useState(props.home.video);

  // console.log(Object.values(props.home), "object values");

  useEffect(() => {
    setPhoto(props.home.photo);
    setVideo(props.home.video);
  }, [props.home]);
  return (
    <>
      {props.home?.photo !== null && props.home?.video !== null ? (
        <div className="video-wrapper">
          <img src={photo} />
          <div className="video-container">
            {" "}
            <video
              width="100%"
              controls
              key={video}
              autoPlay="autoplay"
              muted
              loop={true}
            >
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PageVideo;
