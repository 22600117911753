import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const changePassword = (formData) => {
  return { type: "PASSWORD_UPDATE_DATA_ATTEMPT", payload: formData };
};

const Password = () => {
  const store = useSelector((state) => state);
  const passwordForm = useRef();

  useEffect(() => {
    if (store.profile.isSuccess) {
      setOld_password("");
      setNew_password("");
      setCnf_password("");
    }
  }, [store.profile.isSuccess]);

  // console.log("Store:", store);
  const actions = useDispatch();
  const [old_password, setOld_password] = useState("");
  const [new_password, setNew_password] = useState("");
  const [cnf_password, setCnf_password] = useState("");

  const [old_password_error, setOld_password_error] = useState("");
  const [new_password_error, setNew_password_error] = useState("");
  const [cnf_password_error, setCnf_password_error] = useState("");

  const HandleChangePassword = (event) => {
    event.preventDefault();
    if (old_password == "") {
      setOld_password_error("Old password is required");
    }

    if (new_password == "") {
      setNew_password_error("New password is required");
    }

    if (cnf_password == "") {
      setCnf_password_error("Confirm password is required.");
    }

    const formData = {
      old_password,
      new_password,
      cnf_password,
    };
    actions(changePassword(formData));
  };
  return (
    <div className="card-body">
      <form onSubmit={(e) => HandleChangePassword(e)} ref={passwordForm}>
        {store.profile.isError ? (
          <div className="alert alert-danger" role="alert">
            {store.profile.isError.data.message}
          </div>
        ) : (
          ""
        )}
        {store.profile.isSuccess ? (
          <div className="alert alert-success" role="alert">
            {store.profile.isSuccess.message}
          </div>
        ) : (
          ""
        )}
        <div className="form-group">
          <label htmlFor="old_password">Old Password</label>
          <input
            type="password"
            className="form-control"
            id="old_password"
            aria-describedby="password"
            placeholder="Enter old password"
            value={old_password}
            onChange={(e) => {
              setOld_password(e.target.value);
              setOld_password_error("");
            }}
          />
          <p className="error">{old_password_error}</p>
        </div>
        <div className="form-group">
          <label htmlFor="new_password">New password</label>
          <input
            type="password"
            className="form-control"
            id="new_password"
            aria-describedby="new_password"
            placeholder="Enter new password"
            value={new_password}
            onChange={(e) => {
              setNew_password(e.target.value);
              setNew_password_error("");
            }}
          />
          <p className="error">{new_password_error}</p>
        </div>

        <div className="form-group">
          <label htmlFor="cnf_password">Confirm password</label>
          <input
            type="password"
            className="form-control"
            id="cnf_password"
            aria-describedby="cnf_password"
            placeholder="Enter confirm password"
            value={cnf_password}
            onChange={(e) => {
              setCnf_password(e.target.value);
              setCnf_password_error("");
            }}
          />
          <p className="error">{cnf_password_error}</p>
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Password;
