import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Layout from "../../../layout/layout";

const getBikes = () => {
  return { type: "BIKE_PRESET_FETCH_DATA_ATTEMPT" };
};

const submitForm = (formData, id) => {
  return {
    type: "BIKE_PHOTO_EDIT_DATA_ATTEMPT",
    payload: formData,
    bike_photoid: id,
  };
};

const getBikePhotoDetail = (bike_photoid, cb = false) => {
  return {
    type: "BIKE_PHOTO_DETAIL_DATA_ATTEMPT",
    payload: { bike_photoid: bike_photoid },
    cb,
  };
};

const EditBikePhoto = () => {
  const { id } = useParams();

  const action = useDispatch();
  const navigate = useNavigate();

  const [bike, setBike] = useState("");
  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");
  const [bikeImage, setBikeImage] = useState("");
  const [bikeImageFile, setBikeImageFile] = useState("");
  const [status, setStatus] = useState("Active");

  const [bikeError, setBikeError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [detailError, setDetailError] = useState("");
  const [bikeImageError, setBikeImageError] = useState("");
  const [statusError, setStatusError] = useState("");

  const store = useSelector((state) => state);

  useEffect(() => {
    action(getBikes());
  }, []);

  useEffect(() => {
    action(
      getBikePhotoDetail(id, (detail) => {
        setBike(detail.bike_id);
        setTitle(detail.title);
        setDetail(detail.detail);
        setBikeImage(detail.bike_thumbnail);
        setStatus(detail.status);
      })
    );
  }, []);

  useEffect(() => {
    if (store.bike.isSuccess) {
      toast("Success!");
      navigate("/admin/bike/photos");
    }
  }, [store.bike.isSuccess]);

  const handleBikePhotoForm = (event) => {
    event.preventDefault();
    let error = false;
    if (bike == "") {
      setBikeError("Bike is required.");
      error = true;
    }

    if (title == "") {
      setTitleError("Title is required.");
      error = true;
    }

    if (bikeImage == "") {
      setBikeImageError("Image is required.");
      error = true;
    }

    if (error == true) return false;

    const formData = new FormData();
    formData.append("bikeFiles", bikeImageFile);
    formData.append("bike", bike);
    formData.append("title", title);
    formData.append("detail", detail);
    formData.append("status", status);

    action(submitForm(formData, id));
  };

  const onChangeBikeImg = (event) => {
    setBikeImage(URL.createObjectURL(event.target.files[0]));
    setBikeImageFile(event.target.files[0]);
  };

  return (
    <Layout>
      <div className="page_wrapper">
        <div className="page-header">
          <h1>Edit Bike Photo</h1>
        </div>
        <form onSubmit={(e) => handleBikePhotoForm(e)}>
          <div className="form-group row mb-30">
            <label htmlFor="bike_model" className="col-md-3">
              Bike
            </label>
            <div className="col-md-6">
              {store.bike.bikePresets.length > 0 ? (
                <select
                  value={bike}
                  className="form-control"
                  onChange={(e) => setBike(e.target.value)}
                >
                  <option value="">Select</option>

                  {store.bike.bikePresets.map((bike, key) => {
                    return (
                      <option key={key} value={bike.id}>
                        {bike.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                "No Bikes"
              )}
              <p className="error">{bikeError} </p>
            </div>
          </div>
          <div className="form-group row mb-30">
            <label htmlFor="title" className="col-md-3">
              Title
            </label>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                id="title"
                aria-describedby="emailHelp"
                value={title}
                placeholder="Title"
                onChange={(e) => setTitle(e.target.value)}
              />
              <p className="error">{titleError} </p>
            </div>
          </div>
          <div className="form-group row mb-30">
            <label htmlFor="detail" className="col-md-3">
              Detail
            </label>
            <div className="col-md-6">
              <textarea
                type="text"
                className="form-control"
                id="detail"
                placeholder="Detail"
                defaultValue={detail}
                onChange={(e) => setDetail(e.target.value)}
              ></textarea>
              <p className="error">{detailError} </p>
            </div>
          </div>

          <div className="form-group row mb-30">
            <label htmlFor="bike_image" className="col-md-3">
              Bike Image
            </label>
            <div className="col-md-6">
              <input
                type="file"
                className="form-control-file"
                id="bike_image"
                name="bike_image"
                onChange={(e) => onChangeBikeImg(e)}
                accept="image/*"
              />
              <p className="error">{bikeImageError}</p>
            </div>
            <div className="col-md-3">
              <img src={bikeImage} className="field_image" />
            </div>
          </div>

          <div className="form-group row mb-30">
            <label htmlFor="status" className="col-md-3">
              Status
            </label>
            <div className="col-md-6">
              <select
                value={status}
                className="form-control"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
              <p className="error">{statusError}</p>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="form-group row mb-30">
            <label className="col-md-3"></label>
            <div className="col-md-6">
              <button type="submit" className="btn btn-primary mr-30">
                Submit
              </button>
              <Link to="/admin/bike/photos" className="btn btn-danger">
                Back
              </Link>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditBikePhoto;
