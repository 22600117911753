import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import loadingImage from "../../../assets/imgs/loading.gif";
import TrRow from "./trRow";

const loadcustomers = (page, keyword) => {
  return { type: "CUSTOMER_FETCHING_DATA_ATTEMPT", payload: { page, keyword } };
};

const List = () => {
  const action = useDispatch();
  const store = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState(null);

  useEffect(() => {
    setLoading(true);
    // alert(page);
    action(loadcustomers(page, keyword));
    // setLoading(false);
  }, [page]);

  const loadMorecustomers = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && store.customer.response.has_next_page) {
      setPage(page + 1);
    }
  };

  const searchByKeyword = (keyword) => {
    setPage(1);
    setKeyword(keyword);
    action(loadcustomers(page, keyword));
  };
  return (
    <div className="page_wrapper">
      <div className="page-header">
        <h1>
          Happy Customer{" "}
          <Link
            to="/admin/customer/add"
            className="btn btn-primary float-right"
          >
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add
          </Link>
        </h1>
        <div className="search-wrapper">
          <div className="customer-title-admin">
            <input
              type="text"
              id="myInput"
              placeholder="Search for Keyword.."
              title="Type in a name"
              onKeyDown={(e) => searchByKeyword(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className="col-sd-12 col-md-12  body-list"
          onScroll={(event) => {
            loadMorecustomers(event);
          }}
        >
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Image</th>
                <th scope="col">Status</th>
                <th scope="col"> Action </th>
              </tr>
            </thead>
            <tbody>
              {store.customer.response.customers.length > 0 ? (
                store.customer.response.customers.map((customer) => (
                  <TrRow key={customer.id} customer={customer} />
                ))
              ) : (
                <tr>
                  <td scope="row" colSpan={5} style={{ textAlign: "center" }}>
                    No customer found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default List;
