import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { confirm } from "react-confirm-box";

const deleteRecord = (customerid) => {
  return { type: "CUSTOMER_DELETE_DATA_ATTEMPT", payload: { customerid } };
};

const TrRow = (props) => {
  const action = useDispatch();
  const deletecustomer = async (customerid) => {
    const result = await confirm("Are you sure to delete this customer?");
    if (result) {
      action(deleteRecord(customerid));
    }
  };
  return (
    <tr>
      <th scope="row">{props.customer.id}</th>
      <td>{props.customer.title}</td>
      <td>
        <img src={props.customer.thumbnail} className="img-100" />
      </td>
      <td>
        {" "}
        <span className={props.customer.status}>{props.customer.status}</span>
      </td>
      <td>
        <div className="action">
          <Link to={`/admin/customer/${props.customer.id}/edit`}>
            <FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon>
          </Link>
          <FontAwesomeIcon
            className="link_pointer"
            icon={faTrashCan}
            onClick={() => deletecustomer(props.customer.id)}
          ></FontAwesomeIcon>
        </div>
      </td>
    </tr>
  );
};

export default TrRow;
