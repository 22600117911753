import Layout from "../../layout/layout";
import List from "./blogs/list";

function Blog() {
  return (
    <Layout>
      <List />
    </Layout>
  );
}

export default Blog;
