import React, { useEffect, useState, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { base_url } from "../../../constant";

const submitForm = (formData) => {
  return { type: "BLOG_ADD_DATA_ATTEMPT", payload: formData };
};

const AddForm = () => {
  const action = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [blogVideo, setblogVideo] = useState("");
  const [videoFile, setVideoFile] = useState("");
  const [status, setStatus] = useState("Active");

  const [nameError, setNameError] = useState("");
  const [detailError, setDetailError] = useState("");
  const [imageError, setImageError] = useState("");

  const store = useSelector((state) => state);

  useEffect(() => {
    if (store.blog.isSuccess) {
      toast("Success!");
      navigate("/admin/blogs");
    }
  }, [store.blog.isSuccess]);

  const onChangeImg = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageFile(event.target.files[0]);
  };

  const onChangeVideo = (event) => {
    setblogVideo(URL.createObjectURL(event.target.files[0]));
    setVideoFile(event.target.files[0]);
  };

  const handleForm = (event) => {
    event.preventDefault();
    let error = false;
    if (name == "") {
      setNameError("Blog name is required.");
      error = true;
    }

    if (detail == "") {
      setDetailError("Blog detail is required.");
      error = true;
    }

    if (image == "") {
      setImageError("Image is required.");
      error = true;
    }

    if (error == true) return false;

    const formData = new FormData();
    formData.append("files", imageFile);
    formData.append("video", videoFile);
    formData.append("name", name);
    formData.append("detail", detail);
    formData.append("status", status);
    action(submitForm(formData));
  };

  return (
    <div className="page_wrapper">
      <div className="page-header">
        <h1>Add New Blog</h1>
      </div>

      <form onSubmit={(e) => handleForm(e)}>
        <div className="form-group row mb-30">
          <label htmlFor="bike_model" className="col-md-3">
            Blog Title
          </label>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              id="name"
              aria-describedby="emailHelp"
              value={name}
              placeholder="Blog title"
              onChange={(e) => setName(e.target.value)}
            />
            <p className="error"> {nameError}</p>
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="detail" className="col-md-3">
            Detail
          </label>
          <div className="col-md-9">
            <CKEditor
              editor={ClassicEditor}
              data={detail}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              config={{
                ckfinder: {
                  // Upload the images to the server using the CKFinder QuickUpload command.
                  uploadUrl: base_url + "uplaodImageFromEditor",
                },
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDetail(data);
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
            <p className="error">{detailError}</p>
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="image" className="col-md-3">
            Image
          </label>
          <div className="col-md-6">
            <input
              type="file"
              className="form-control-file"
              id="image"
              name="image"
              onChange={(e) => onChangeImg(e)}
              accept="image/*"
            />
            <p className="error">{imageError}</p>
          </div>
          <div className="col-md-3">
            <img src={image} className="field_image" />
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="image" className="col-md-3">
            Video
          </label>
          <div className="col-md-6">
            <input
              type="file"
              className="form-control-file"
              id="video"
              name="video"
              onChange={(e) => onChangeVideo(e)}
              accept="video/*"
            />
            <p className="error"></p>
          </div>
          <div className="col-md-3">
            {blogVideo ? (
              <video width="100%" key={blogVideo} controls>
                <source src={blogVideo} type="video/mp4" />
              </video>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="status" className="col-md-3">
            Status
          </label>
          <div className="col-md-6">
            <select
              value={status}
              className="form-control"
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="form-group row mb-30">
          <label className="col-md-3"></label>
          <div className="col-md-6">
            <button type="submit" className="btn btn-primary mr-30">
              Submit
            </button>
            <Link to="/admin/blogs" className="btn btn-danger">
              Back
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddForm;
