import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState, Component } from "react";

const submitModel = (formData, id) => {
  return {
    type: "CUSTOMER_EDIT_DATA_ATTEMPT",
    payload: formData,
    customerid: id,
  };
};
const getDetail = (customerid, cb = false) => {
  return {
    type: "CUSTOMER_DETAIL_DATA_ATTEMPT",
    payload: { customerid: customerid },
    cb,
  };
};

const getBikes = () => {
  return { type: "BIKE_PRESET_FETCH_DATA_ATTEMPT" };
};

const EditForm = () => {
  const { id } = useParams();
  //   alert(id);
  const store = useSelector((state) => state);

  const action = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [status, setStatus] = useState("Active");

  const [bike, setBike] = useState("");
  const [colours, setColours] = useState([]);
  const [color, setColor] = useState("");

  const [bikeError, setBikeError] = useState("");
  const [colorError, setColorError] = useState("");
  const [nameError, setNameError] = useState("");
  const [detailError, setDetailError] = useState("");
  const [imageError, setImageError] = useState("");

  useEffect(() => {
    action(
      getDetail(id, (detail) => {
        setBike(detail.bike_id);
        setColor(detail.bike_color_id);
        setName(detail.title);
        setDetail(detail.detail);
        setImage(detail.thumbnail);
        setStatus(detail.status);
        console.log("bikepresets", store.bike.bikePresets);

        setColours(
          store.bike.bikePresets.filter((v) => v.id == detail.bike_id)[0].colors
        );
      })
    );
  }, []);

  useEffect(() => {
    action(getBikes());
  }, []);

  useEffect(() => {
    if (store.customer.isSuccess) {
      toast("Success!");
      navigate("/admin/customers");
    }
  }, [store.customer.isSuccess]);

  const onChangeImg = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageFile(event.target.files[0]);
  };

  const handleForm = (event) => {
    event.preventDefault();
    let error = false;
    if (name == "") {
      setNameError("Customer name is required.");
      error = true;
    }

    if (!detail) {
      setDetailError("Customer detail is required.");
      error = true;
    }

    if (bike == "") {
      setBikeError("Bike is required.");
      error = true;
    }

    if (color == "") {
      setColorError("Bike color is required.");
      error = true;
    }

    if (error == true) return false;

    const formData = new FormData();
    formData.append("files", imageFile);
    formData.append("bikeid", bike);
    formData.append("bike_color_id", color);
    formData.append("name", name);
    formData.append("detail", detail);
    formData.append("status", status);
    action(submitModel(formData, id));
  };

  return (
    <div className="page_wrapper">
      <div className="page-header">
        <h1>Edit customer</h1>
      </div>

      <form onSubmit={(e) => handleForm(e)}>
        <div className="form-group row mb-30">
          <label htmlFor="bike_model" className="col-md-3">
            Bike
          </label>
          <div className="col-md-6">
            {store.bike.bikePresets.length > 0 ? (
              <select
                value={bike}
                className="form-control"
                onChange={(e) => {
                  const b = e.target.value;
                  setColours(
                    store.bike.bikePresets.filter(
                      (v) => v.id == e.target.value
                    )[0].colors
                  );
                  setBike(b);
                }}
              >
                <option value="">Select</option>

                {store.bike.bikePresets.map((b, key) => {
                  return (
                    <option key={key} value={b.id}>
                      {b.name}
                    </option>
                  );
                })}
              </select>
            ) : (
              "No Bikes"
            )}
            <p className="error">{bikeError} </p>
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="bike_model" className="col-md-3">
            Color
          </label>
          <div className="col-md-6">
            <select
              value={color}
              className="form-control"
              onChange={(e) => setColor(e.target.value)}
            >
              <option value=""></option>
              {colours.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item?.title}
                  </option>
                );
              })}
            </select>
            <p className="error">{colorError} </p>
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="bike_model" className="col-md-3">
            customer Title
          </label>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              id="name"
              aria-describedby="emailHelp"
              value={name}
              placeholder="customer title"
              onChange={(e) => setName(e.target.value)}
            />
            <p className="error"> {nameError}</p>
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="detail" className="col-md-3">
            Detail
          </label>
          <div className="col-md-6">
            <textarea
              name="detail"
              placeholder="Enter detail"
              className="form-control"
              onChange={(e) => setDetail(e.target.value)}
              value={detail}
            />
            <p className="error"> {detailError}</p>
          </div>
        </div>
        <div className="form-group row mb-30">
          <label htmlFor="image" className="col-md-3">
            Image
          </label>
          <div className="col-md-6">
            <input
              type="file"
              className="form-control-file"
              id="image"
              name="image"
              onChange={(e) => onChangeImg(e)}
              accept="image/*"
            />
            <p className="error">{imageError}</p>
          </div>
          <div className="col-md-3">
            <img src={image} className="field_image" />
          </div>
        </div>

        <div className="form-group row mb-30">
          <label htmlFor="status" className="col-md-3">
            Status
          </label>
          <div className="col-md-6">
            <select
              value={status}
              className="form-control"
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="form-group row mb-30">
          <label className="col-md-3"></label>
          <div className="col-md-6">
            <button type="submit" className="btn btn-primary mr-30">
              Submit
            </button>
            <Link to="/admin/customers" className="btn btn-danger">
              Back
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditForm;
