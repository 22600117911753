import Layout from "../../layout/layout";
import "antd/dist/antd.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import loadingImage from "../../assets/imgs/loading.gif";
import { DatePicker } from "antd";

const loadorders = (page, keyword, date) => {
  return {
    type: "ORDER_FETCHING_DATA_ATTEMPT",
    payload: { page, keyword, date },
  };
};

function Orders() {
  const action = useDispatch();
  const store = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [date, setDate] = useState(null);

  useEffect(() => {
    setLoading(true);
    // alert(page);
    action(loadorders(page, keyword, date));
    // setLoading(false);
  }, [page]);

  const loadMoreOrders = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && store.customer.response.has_next_page) {
      setPage(page + 1);
    }
  };

  const searchByKeyword = (keyword) => {
    setPage(1);
    setKeyword(keyword);
    action(loadorders(page, keyword, date));
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const searchByDate = (date) => {
    if (date) date = formatDate(date);
    setPage(1);
    setDate(date);
    action(loadorders(page, keyword, date));
  };

  return (
    <Layout>
      <div className="page_wrapper">
        <div className="page-header">
          <h1>Orders</h1>
          <div className="search-wrapper">
            <div className="customer-title-admin">
              <input
                type="text"
                id="myInput"
                placeholder="Search for Keyword.."
                title="Type in a name"
                onKeyDown={(e) => searchByKeyword(e.target.value)}
              />
            </div>
            <div className="customer-title-admin">
              <DatePicker
                dateFormat="yyyy/MM/dd"
                selected={date}
                onChange={(date) => searchByDate(date)}
                placeholder="Search for date.."
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-sd-12 col-md-12  body-list"
            onScroll={(event) => {
              loadMoreOrders(event);
            }}
          >
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Bike</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Contact No</th>
                  <th scope="col">Address</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {store.bike.bikeOrders.orders.length > 0 ? (
                  store.bike.bikeOrders.orders.map((order) => (
                    <tr>
                      <th scope="row">{order.id}</th>
                      <td>{order.bike}</td>
                      <td>{order.name}</td>
                      <td>{order.email}</td>
                      <td>{order.mobile}</td>
                      <td>{order.address}</td>
                      <td>{order.added_at}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td scope="row" colSpan={5} style={{ textAlign: "center" }}>
                      No Order found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Orders;
